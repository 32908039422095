import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './LanguageSelector.css';
import { AuthContext } from '../Authentication/AuthContext';

const LanguageSelector = () => {
  const { setLanguage } = useContext(AuthContext); // Access setLanguage from AuthContext
  const navigate = useNavigate();

  const handleLanguageSelect = (language) => {
    localStorage.setItem('language', language); 
    setLanguage(language); // Update language context globally
    navigate('/home'); // Redirect to home page
  };

  return (
    <div className="language-selector-container">
      <h1 className="language-selector-heading">Select Your Language</h1>
      <div className="language-options">
        <button onClick={() => handleLanguageSelect('English')} className="language-button">English</button>
        <button onClick={() => handleLanguageSelect('தமிழ்')} className="language-button">தமிழ்</button>
        <button onClick={() => handleLanguageSelect('Deutsch')} className="language-button">Deutsch</button>
      </div>
    </div>
  );
};

export default LanguageSelector;
