import React from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import Songs from './Songs/Songs';
import Articles from './Articles/Articles';
import './Dashboard.css';

function Dashboard() {
  return (
    <div className="dashboard">
      <aside className="sidebar">
        <ul>
          <li>
            <NavLink 
              to="/admin/dashboard/songs"
              className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}
            >
              Songs
            </NavLink>
          </li>
          <li>
            <NavLink 
              to="/admin/dashboard/articles"
              className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}
            >
              Articles
            </NavLink>
          </li>
        </ul>
      </aside>
      <main className="main-content">
        <h2 className="dashboard-header">Dashboard</h2>
        <Routes>
          <Route path="songs" element={<Songs />} />
          <Route path="articles" element={<Articles />} />
        </Routes>
      </main>
    </div>
  );
}

export default Dashboard;
