import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import DailyVerse from '../DailyVerse/DailyVerse';
import ScreenSaver from '../ScreenSaver/ScreenSaver';
import Footer from '../Footer/Footer';
import { FaBible, FaSearch } from 'react-icons/fa';
import { AuthContext } from '../Authentication/AuthContext';
import './Home.css';

function Home() {
  const { language } = useContext(AuthContext); // Access language from context

  const handleButtonClick = (route) => {
    window.location.href = route;
  };

  // Determine Bible text based on selected language
  const bibleText = language === 'English' ? 'Holy Bible' : language === 'Deutsch' ? 'Die Bibel' : 'பரிசுத்த வேதாகமம்';
  const searchVerseText = language === 'English' ? 'Find Verse' : language === 'Deutsch' ? 'Vers Suchen' : 'வசனம் தேடுதல்';
  const searchWordText = language === 'English' ? 'Find Word' : language === 'Deutsch' ? 'Wort Suchen' : 'சொல் தேடுதல்';

  return (
    <div className="home-container">
      <div className="bible-contents-container">
        <ScreenSaver className="screen-saver" />
        <div>
          <DailyVerse />
          <div className="button-container">
            <Button variant="contained" color="primary" onClick={() => handleButtonClick("/bible")} className="custom-button">
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <FaBible className="icon-size" color='#0058BD' />
                <span className="text-size" style={{ marginTop: '5px' }}>{bibleText}</span>
              </div>
            </Button>
            <Button variant="contained" color="primary" onClick={() => handleButtonClick("/bible-verse-find")} className="custom-button">
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <FaSearch className="icon-size" color='#01A86B' />
                <span className="text-size" style={{ marginTop: '5px' }}>{searchVerseText}</span>
              </div>
            </Button>
            <Button variant="contained" color="primary" onClick={() => handleButtonClick("/search-verse-by-word")} className="custom-button">
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <FaSearch className="icon-size" color='#BD003F' />
                <span className="text-size" style={{ marginTop: '5px' }}>{searchWordText}</span>
              </div>
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
