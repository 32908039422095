import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import LanguageSelector from './components/Authentication/LanguageSelector';
import OldTestament from './components/Bible/OldTestament/OldTestament';
import OldTestamentChapters from './components/Bible/OldTestament/OldTestamentChapters';
import NewTestament from './components/Bible/NewTestament/NewTestament';
import NewTestamentChapters from './components/Bible/NewTestament/NewTestamentChapters'; 
import OldTestamentVerses from './components/Bible/OldTestament/OldTestamentVerses';
import NewTestamentVerses from './components/Bible/NewTestament/NewTestamentVerses';
import Bible from './components/Bible/Bible';
import SearchWord from './components/Bible/SearchWord/SearchWord';
import DailyVerse from './components/DailyVerse/DailyVerse';
import ScreenSaver from './components/ScreenSaver/ScreenSaver';
import Contact from './components/Contact/Contact';
import Home from './components/Home/Home'; 
import TopHeader from './components/Header/TopHeader';
import NewsForm from './components/News/NewsForm';
import News from './components/News/News';
import NewsContent from './components/News/NewsContent';
import ShareTextImage from './components/TextToImage/ShareTextImage';
import ImageUploadForm from './components/ImageUpload/ImageUploadForm';
import BibleVerseFind from './components/Bible/VerseFind/BibleVerseFind';
import SongDetails from './components/Songs/SongDetails'; 
import SingerAndAlbumList from './components/Songs/SingerAndAlbumList';
import AdminAuth from './components/Admin/AdminAuth';
import Songs from './components/Admin/Songs/Songs';
import Dashboard from './components/Admin/Dashboard';
import Support from './components/Authentication/Support';
import PrivacyPolicy from './components/Authentication/PrivacyPolicy';
import { AuthProvider } from './components/Authentication/AuthContext';
import Categories from './components/Articles/Categories';
import SubCategories from './components/Articles/SubCategories';
import Articles from './components/Articles/Articles'; 
import Article from './components/Articles/Article';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'English');

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (!storedLanguage) {
      navigate('/');
    }
  }, [navigate]);

  return (
    <AuthProvider>
      <div>
        {location.pathname !== '/' && <TopHeader language={language} setLanguage={setLanguage} />}
        <div>
          <Routes>
            <Route path="/" element={<LanguageSelector setLanguage={setLanguage} />} />
            <Route path="/home" element={<Home />} />
            <Route path="/old-testament" element={<OldTestament />} />
            <Route path="/old-testament/chapters/:book" element={<OldTestamentChapters />} /> 
            <Route path="/old-testament/chapters/:book/:chapter" element={<OldTestamentVerses />} />
            <Route path="/new-testament" element={<NewTestament />} />
            <Route path="/new-testament/chapters/:book" element={<NewTestamentChapters />} /> 
            <Route path="/new-testament/chapters/:book/:chapter" element={<NewTestamentVerses />} />
            <Route path="/bible" element={<Bible />} />
            <Route path="/bible-verse-find" element={<BibleVerseFind />} />
            <Route path="/search-verse-by-word" element={<SearchWord />} />
            <Route path="/daily-verse" element={<DailyVerse />} />
            <Route path="/screen-saver" element={<ScreenSaver />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/newsform" element={<NewsForm />} />
            <Route path="/news" element={<News />} />
            <Route path="/news/:id" element={<NewsContent />} />
            <Route path="/singers" element={<SingerAndAlbumList />} />
            <Route path="/songs/:id" element={<SongDetails />} />
            <Route path="/shareimages" element={<ShareTextImage />} />
            <Route path="/imageupload" element={<ImageUploadForm />} />
            <Route path="/admin" element={<AdminAuth />} />
            <Route path="/Songs" element={<Songs />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/admin/dashboard/*" element={<Dashboard />} />
            <Route path="/support" element={<Support />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/Categories" element={<Categories />} />
            <Route path="/subcategories/:categoryId" element={<SubCategories />} /> 
            <Route path="/articles-list/:subcategoryId" element={<Articles />} />
            <Route path="/article/:articleId" element={<Article />} /> 
          </Routes>
        </div>
      </div>
    </AuthProvider>
  );
}

export default App;
