import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import backendUrl from '../../../BackEndURL';
import './NewTestamentChapters.css';
import { AuthContext } from '../../Authentication/AuthContext';
import { getEnglishBookName, getTamilBookName, getDeutschBookName } from './BookNames';

function NewTestamentChapters() {
  const { book } = useParams();
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { language } = useContext(AuthContext);

  useEffect(() => {
    fetchChapters();
  }, [book]);

  const fetchChapters = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/sections/2/${book}`);
      if (!response.data || !response.data.chapters) {
        throw new Error('Failed to fetch chapters');
      }
      setChapters(response.data.chapters);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const getBookName = (bookName) => {
    switch (language) {
      case 'English':
        return getEnglishBookName(bookName);
      case 'Tamil':
        return getTamilBookName(bookName);
      case 'Deutsch':
        return getDeutschBookName(bookName);
      default:
        return bookName;
    }
  };

  if (loading) {
    return (
      <div className="spinner-container-chapters">
        <div className="loading-spinner-chapters"></div>
        <p>Loading chapters...</p>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="new-testament-chapters-container">
      <h2>{getBookName(book)}</h2>
      <div className="new-testament-chapters-list">
        {chapters.map((chapter) => (
          <Link
            key={chapter.chapter_number}
            to={`/new-testament/chapters/${book}/${chapter.chapter_number}`}
            className="new-testament-chapter-link"
          >
            {language === 'Tamil'
              ? `அதிகாரம் ${chapter.chapter_number}`
              : language === 'Deutsch'
              ? `Kapitel ${chapter.chapter_number}`
              : `Chapter ${chapter.chapter_number}`}
          </Link>
        ))}
      </div>
    </div>
  );
}

export default NewTestamentChapters;
