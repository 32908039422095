import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import serverUrl from '../../BackEndURL';
import { AuthContext } from '../Authentication/AuthContext';
import './Article.css';  

const Article = () => {
  const { articleId } = useParams();
  const { state } = useLocation();  
  const [article, setArticle] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { language } = useContext(AuthContext);  
  const [initialLanguage, setInitialLanguage] = useState(language);  
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        let endpoint;

        if (language === 'Tamil' || language === 'தமிழ்') {
          endpoint = `/api/articles/${articleId}`;
        } else if (language === 'Deutsch') {
          endpoint = `/api/articles-deutsch/${articleId}`;
        } else {
          endpoint = `/api/articles-english/${articleId}`;
        }

        console.log(`Fetching article with ID: ${articleId} in language: ${language}`);
        const response = await axios.get(`${serverUrl}${endpoint}`);
        setArticle(response.data);
      } catch (error) {
        console.error('Error fetching article:', error);
        setError('Error fetching article');
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [articleId, language]);

  useEffect(() => {
    if (language !== initialLanguage) {
      navigate('/Categories');
    }
  }, [language, initialLanguage, navigate]);

  const isBibleVerse = (text) => text.trim().startsWith('*');
  const isStyledParagraph = (text) => text.trim().startsWith('#');
  const isBoldText = (text) => text.trim().startsWith('@');

  const removeSpecialCharacter = (text) => {
    if (isBibleVerse(text) || isStyledParagraph(text) || isBoldText(text)) {
      return text.trim().substring(1).trim();
    }
    return text;
  };

  if (loading) {
    return <p>Loading article...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className='article-details-container'>
      <div className='article-details'>
        <h2 className='highlight'>{article.header}</h2>
        <div className='text'>
          {article.text && article.text.split('\n').map((paragraph, index) => (
            <p
              key={index}
              className={
                isBibleVerse(paragraph) ? 'bible-verse' :
                isStyledParagraph(paragraph) ? 'styled-paragraph' :
                isBoldText(paragraph) ? 'bold-text' : ''
              }
            >
              {removeSpecialCharacter(paragraph)}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Article;
