const EnglishToTamilWords = {
    Subcategories: "தலைப்புக்கள்",
    Articles: "கட்டுரைகள்",
    God: "பிதா",
    Jesus: "இயேசுகிறிஸ்து",
    JesusQuestionAnswer: "இயேசுகிறிஸ்து : கேள்வி - பதில்",
    HolySpirit: "பரிசுத்த ஆவியானவர்",
    Trinity: "திரித்துவம்",
    Prophecies: "தீர்க்கதரிசனம்",
    Science: "அறிவியல்",
    History: "சரித்திரம்", 
    Bible: "வேதாகமம்",
    BibleStudy: "வேதாகம கற்கை",
    Questions: "கேள்விகள் - பதில்கள்",
};

const EnglishToCorrectEnglish = {
    Subcategories: "Topics",
    Articles: "Articles",
    God: "God",
    Jesus: "Jesus Christ",
    JesusQuestionAnswer: "Jesus : Question - Answer",
    HolySpirit: "Holy Spirit",
    Trinity: "Trinity",
    Prophecies: "Prophecy",
    Science: "Science",
    History: "History",
    Bible: "Bible",
    BibleStudy: "Bible Study",
    Questions: "Questions - Answers",
};

const EnglishToDeutsch = {
    Subcategories: "Themen",
    Articles: "Articles",
    God: "Gott",
    Jesus: "Jesus Christus",
    JesusQuestionAnswer: "Jesus : Frage - Antwort",
    HolySpirit: "Heiliger Geist",
    Trinity: "Trinität",
    Prophecies: "Prophezeiung",
    Science: "Wissenschaft",
    History: "Geschichte",
    Bible: "Bibel",
    BibleStudy: "Bibelstudium",
    Questions: "Fragen - Antworten",
};

export { EnglishToTamilWords, EnglishToCorrectEnglish, EnglishToDeutsch };
