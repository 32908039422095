import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './BibleVerseFind.css'; 
import { getEnglishBookName, getTamilBookName, getDeutschBookName } from './BookNames'; 
import backendUrl from '../../../BackEndURL';
import { AuthContext } from '../../Authentication/AuthContext';  

function BibleVerseFind() {
    const { language } = useContext(AuthContext);
    const [testaments, setTestaments] = useState([]);
    const [selectedTestament, setSelectedTestament] = useState('');
    const [books, setBooks] = useState([]);
    const [selectedBook, setSelectedBook] = useState('');
    const [chapters, setChapters] = useState([]);
    const [selectedChapter, setSelectedChapter] = useState('');
    const [verseNumber, setVerseNumber] = useState('');  
    const [filteredVerses, setFilteredVerses] = useState([]); // To store the filtered verses
    const [error, setError] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [selectedLanguages, setSelectedLanguages] = useState([language.toLowerCase()]);
    const availableLanguages = ['tamil', 'english', 'deutsch'];
    const [isLoading, setIsLoading] = useState(false);

    // Automatically set the default language and reset fields when the language changes
    useEffect(() => {
        setSelectedLanguages([language.toLowerCase() === 'தமிழ்' ? 'tamil' : language.toLowerCase()]);
        resetFormFields(); 
    }, [language]);

    // Fetching testaments on mount
    useEffect(() => {
        axios.get(`${backendUrl}/api/sections`)
            .then(response => {
                setTestaments(response.data);
            })
            .catch(error => console.error('Error fetching testaments:', error));
    }, []);

    // Fetch books when a testament is selected
    useEffect(() => {
        if (selectedTestament) {
            const testament = testaments.find(t => t.id === parseInt(selectedTestament));
            if (testament) {
                setBooks(testament.books);
            }
        }
    }, [selectedTestament, testaments]);

    // Fetch chapters when a book is selected
    useEffect(() => {
        if (selectedBook) {
            axios.get(`${backendUrl}/api/sections/${selectedTestament}/${selectedBook.toLowerCase()}`)
                .then(response => {
                    setChapters(response.data.chapters);
                })
                .catch(error => console.error('Error fetching chapters:', error));
        }
    }, [selectedBook, selectedTestament]);

    // Fetch verses when a chapter is selected
    useEffect(() => {
        if (selectedChapter) {
            setIsLoading(true); 
            axios.get(`${backendUrl}/api/sections/${selectedTestament}/${selectedBook.toLowerCase()}/${selectedChapter}`)
                .then(response => {
                    setFilteredVerses([]); // Clear previous results
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching verses:', error);
                    setIsLoading(false); 
                });
        }
    }, [selectedChapter, selectedBook, selectedTestament]);

    // Function to get the testament name based on the selected language
    const getTestamentName = (testamentName) => {
        if (language === 'English') return testamentName;
        if (language === 'Deutsch') {
            return testamentName === 'Old Testament' ? 'Altes Testament' : 'Neues Testament';
        }
        if (language === 'தமிழ்') {
            return testamentName === 'Old Testament' ? 'பழைய ஏற்பாடு' : 'புதிய ஏற்பாடு';
        }
        return testamentName;  
    };

    // Function to get book names based on the selected language
    const getBookName = (book) => {
        switch (language) {
            case 'English':
                return getEnglishBookName(book.book_name);
            case 'Deutsch':
                return getDeutschBookName(book.book_name);
            case 'தமிழ்':
                return getTamilBookName(book.book_name);
            default:
                return book.book_name; 
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Validate the input and fetch the correct verse or verse range
        if (!verseNumber) {
            setError('Please enter a verse number.');
            return;
        }

        setIsLoading(true);

        axios.get(`${backendUrl}/api/sections/${selectedTestament}/${selectedBook.toLowerCase()}/${selectedChapter}`)
            .then(response => {
                const verses = response.data.verses || [];
                let selectedVerses = [];

                if (verseNumber.includes('-')) {
                    const [start, end] = verseNumber.split('-').map(Number);
                    if (start > end || start <= 0 || end <= 0) {
                        setError('Invalid verse range.');
                        setIsLoading(false);
                        return;
                    }
                    selectedVerses = verses.filter(verse => verse.verse_number >= start && verse.verse_number <= end);
                } else {
                    const verseNum = parseInt(verseNumber.trim());
                    if (isNaN(verseNum) || verseNum <= 0) {
                        setError('Invalid verse number.');
                        setIsLoading(false);
                        return;
                    }
                    selectedVerses = verses.filter(verse => parseInt(verse.verse_number) === verseNum);  
                }

                if (selectedVerses.length === 0) {
                    setError('No verses found for the selected input.');
                } else {
                    setError('');
                    setIsSubmitted(true);
                    setFilteredVerses(selectedVerses); // Only show filtered verses
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching verses:', error);
                setIsLoading(false);
            });

        // Clear only the verse number input
        setVerseNumber('');
    };

    const resetFormFields = () => {
        setSelectedTestament('');
        setSelectedBook('');
        setSelectedChapter('');
        setVerseNumber('');
        setFilteredVerses([]);
        setError('');
        setIsSubmitted(false);
    };

    const toggleLanguage = (lang) => {
        if (selectedLanguages.includes(lang)) {
            setSelectedLanguages(selectedLanguages.filter(language => language !== lang));
        } else {
            setSelectedLanguages([...selectedLanguages, lang]);
        }
    };

    // Function to render verses dynamically by placing the default language first
    const renderVerse = (verse, index) => {
        // Prepare the verses for each language
        const verseContent = {
            tamil: selectedLanguages.includes('tamil') ? `${verse.tamil}` : null,
            english: selectedLanguages.includes('english') ? `${verse.english}` : null,
            deutsch: selectedLanguages.includes('deutsch') ? `${verse.deutsch}` : null,
        };

        // Reorder languages to place the default language first
        const orderedLanguages = [language.toLowerCase()].concat(
            selectedLanguages.filter(lang => lang !== language.toLowerCase())
        );

        return (
            <div key={index} className="verse-row">
                <div className="verse-number-column">
                    <p>{verse.verse_number}.</p>
                </div>
                <div className="verse-text-column">
                    {orderedLanguages.map((lang, idx) => (
                        verseContent[lang] && <p key={idx} className="verse-line">{verseContent[lang]}</p>
                    ))}
                </div>
            </div>
        );
    };

    const labels = {
        English: {
            testament: 'Testament',
            book: 'Book',
            chapter: 'Chapter',
            verse: 'Verse',
            language: 'Language',
            selectTestament: 'Select the Testament',
            selectBook: 'Select the Book',
            selectChapter: 'Select the Chapter',
        },
        Tamil: {
            testament: 'ஏற்பாடு',
            book: 'ஆகமம்',
            chapter: 'அதிகாரம்',
            verse: 'வசனம்',
            language: 'மொழி',
            selectTestament: 'ஏற்பாட்டைத் தேர்ந்தெடுக்கவும்',
            selectBook: 'ஆகமத்தைத் தேர்ந்தெடுக்கவும்',
            selectChapter: 'அதிகாரத்தைத் தேர்ந்தெடுக்கவும்',
        },
        Deutsch: {
            testament: 'Testament',
            book: 'Buch',
            chapter: 'Kapitel',
            verse: 'Vers',
            language: 'Sprache',
            selectTestament: 'Wähle das Testament',
            selectBook: 'Wähle das Buch',
            selectChapter: 'Wähle das Kapitel',
        }
    };

    const currentLabels = language === 'English' ? labels.English :
                          language === 'Deutsch' ? labels.Deutsch : labels.Tamil;

    const otherLanguages = availableLanguages.filter(lang => !selectedLanguages.includes(lang));

    return (
        <div className="bibleversefind-container">
            <div className="bibleversefind-form-container">
                <form onSubmit={handleSubmit}>
                    <div className="bibleversefind-form-row">
                        <label htmlFor="testament">{currentLabels.testament}</label>
                        <select id="testament" value={selectedTestament} onChange={(e) => setSelectedTestament(e.target.value)}>
                            <option value="">{currentLabels.selectTestament}</option>
                            {testaments.map(testament => (
                                <option key={testament.id} value={testament.id}>
                                    {getTestamentName(testament.testament)}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="bibleversefind-form-row">
                        <label htmlFor="book">{currentLabels.book}</label>
                        <select id="book" value={selectedBook} onChange={(e) => setSelectedBook(e.target.value)}>
                            <option value="">{currentLabels.selectBook}</option>
                            {books.map(book => (
                                <option key={book.id} value={book.book_name.toLowerCase()}>
                                    {getBookName(book)}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="bibleversefind-form-row">
                        <label htmlFor="chapter">{currentLabels.chapter}</label>
                        <select id="chapter" value={selectedChapter} onChange={(e) => setSelectedChapter(e.target.value)}>
                            <option value="">{currentLabels.selectChapter}</option>
                            {chapters.map(chapter => (
                                <option key={chapter.id} value={chapter.chapter_number}>
                                    {chapter.chapter_number}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="bibleversefind-form-row">
                        <label htmlFor="verse">{currentLabels.verse}</label>
                        <input type="text" id="verse" value={verseNumber} onChange={(e) => setVerseNumber(e.target.value)} placeholder="Example: 1 or 1-14" />
                    </div>

                    <div className="bibleversefind-form-row">
                        <label>{currentLabels.language}</label>
                        <div className="bibleversefind-language-checkboxes">
                            {selectedLanguages.map(lang => (
                                <label key={lang}>
                                    <input type="checkbox" value={lang} checked={true} onChange={() => toggleLanguage(lang)} />
                                    {lang === 'tamil' ? 'Tamil' : lang === 'english' ? 'English' : 'Deutsch'}
                                </label>
                            ))}
                            {otherLanguages.map(lang => (
                                <label key={lang}>
                                    <input type="checkbox" value={lang} checked={false} onChange={() => toggleLanguage(lang)} />
                                    {lang === 'tamil' ? 'Tamil' : lang === 'english' ? 'English' : 'Deutsch'}
                                </label>
                            ))}
                        </div>
                    </div>

                    <div className="bibleversefind-form-row bibleversefind-submit-reset-buttons">
                        <button type="submit" className="bibleversefind-submit-button">Submit</button>
                        {isSubmitted && <button type="button" onClick={resetFormFields} className="bibleversefind-reset-button">Reset</button>}
                    </div>
                </form>
            </div>

            <div className="bibleversefind-result-container">
                {isLoading ? (
                    <div className="loading-indicator">Loading...</div> 
                ) : isSubmitted && filteredVerses.length > 0 ? (
                    filteredVerses.map((verse, index) => renderVerse(verse, index))
                ) : isSubmitted && !filteredVerses.length ? (
                    <p>No verses found for the selected input.</p>
                ) : (
                    <p>{`Note: ${currentLabels.selectTestament}, ${currentLabels.selectBook}, ${currentLabels.selectChapter}.`}</p>
                )}
                {error && <p>{error}</p>}
            </div>
        </div>
    );
}

export default BibleVerseFind;
