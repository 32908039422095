import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h2>Privacy Policy for Bible-Ministries</h2>
      <p>
        Bible-Ministries (“we” or “our”) is committed to protecting the privacy of all users (“you” or “user”) of our app, HolyBible. This Privacy Policy provides detailed information about how we handle user data in accordance with our User Data policy and explains our data practices.
      </p>

      <h3>Data Collection and Usage</h3>
      <p>
        HolyBible does not collect, store, or share any personal or sensitive user data. No analytics, tracking, or third-party data collection tools are integrated within this app.
      </p>

      <h3>Third-Party Services</h3>
      <p>
        HolyBible does not use any third-party services or integrations that collect, store, or share user data.
      </p>

      <h3>Data Retention and Deletion</h3>
      <p>
        Since we do not collect any data, there is no data retention or deletion policy required for HolyBible. We assure our users that their privacy is protected as no data is stored or processed.
      </p>

      <h3>Changes to This Privacy Policy</h3>
      <p>
        We may update our Privacy Policy from time to time. However, any changes will be clearly communicated, and our commitment to not collect or share data will remain unchanged.
      </p>

      <h3>Contact Us</h3>
      <p>
        If you have questions or concerns about this Privacy Policy, please contact us at:
        <a href="mailto:kugathaj@gmail.com"> kugathaj@gmail.com</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
