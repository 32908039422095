import { useState, useEffect } from 'react';
import axios from 'axios';
import url from '../../../BackEndURL';

const baseURL = `${url}/api`;
 
function TamilArticle() {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [articles, setArticles] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchCategories();
    fetchSubcategories();
    fetchArticles();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${baseURL}/categories`);
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  };

  const fetchSubcategories = async () => {
    try {
      const response = await axios.get(`${baseURL}/subcategories`);
      setSubcategories(response.data);
    } catch (error) {
      console.error("Error fetching subcategories", error);
    }
  };

  const fetchArticles = async () => {
    try {
      const response = await axios.get(`${baseURL}/articles`);
      setArticles(response.data);
    } catch (error) {
      console.error("Error fetching articles", error);
    }
  };

  const filterSubcategoriesAndArticles = (categoryId) => {
    const filteredSubcategories = subcategories.filter(subcategory => subcategory.category_id === parseInt(categoryId));
    const filteredArticles = articles.filter(article => article.category_id === parseInt(categoryId));
    setFilteredSubcategories(filteredSubcategories);
    setFilteredArticles(filteredArticles);
  };

  const filterArticles = (subcategoryId) => {
    const filteredArticles = articles.filter(article => article.subcategory_id === parseInt(subcategoryId));
    setFilteredArticles(filteredArticles);
  };

  return {
    categories,
    subcategories,
    articles,
    filteredSubcategories,
    filteredArticles,
    message,
    setMessage,
    fetchCategories,
    fetchSubcategories,
    fetchArticles,
    filterSubcategoriesAndArticles,
    filterArticles
  };
}

export default TamilArticle;
