import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaInstagram, FaWhatsapp, FaTelegram, FaBible, FaBars, FaSearch, FaMusic, FaHome } from 'react-icons/fa';
import logo from './image/logo2.png'; 
import './TopHeader.css';
import { AuthContext } from '../Authentication/AuthContext';

function TopHeader() {
  const { language, setLanguage } = useContext(AuthContext);  
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(location.pathname);
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('');

  useEffect(() => {
    setActiveMenu(location.pathname);
  }, [location.pathname]);

  const handleMenuClick = (path, name) => {
    setActiveMenu(path);
    setSelectedMenu(name);
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    localStorage.setItem('language', selectedLanguage);  
  };

  const menuItems = {
    home: language === 'English' ? 'HOME' : language === 'தமிழ்' ? 'முகப்பு' : 'Zuhause',
    articles: language === 'English' ? 'Articles' : language === 'தமிழ்' ? 'கட்டுரைகள்' : 'Artikel',
    bible: language === 'English' ? 'Bible' : language === 'தமிழ்' ? 'வேதாகமம்' : 'Bibel',
    verses: language === 'English' ? 'Verses' : language === 'தமிழ்' ? 'வசனங்கள்' : 'Verse',
    words: language === 'English' ? 'Words' : language === 'தமிழ்' ? 'சொற்கள்' : 'Wörter',
    songs: language === 'English' ? 'Songs' : language === 'தமிழ்' ? 'பாடல்கள்' : 'Lieder',
  };

  const selectedLanguageText = language === 'English' ? 'Language' : 
                               language === 'தமிழ்' ? 'மொழி' : 
                               'Sprache';

  return (
    <div>
      <div className="top-header">
        <div className="logo-menu-container">
          <div className="logo-container">
            <Link to="/" className="logo-link" onClick={() => handleMenuClick('/', '')}>
              <img src={logo} alt="Logo" className="logo" />
            </Link>
          </div>
          <div className="menu-toggle" onClick={toggleMenu}>
            {selectedMenu && <span className="selected-menu">{selectedMenu}</span>} 
            <FaBars size={25} /> MENU
          </div>
          <div className={`menu ${menuOpen ? 'open' : ''}`}>
            <Link 
              to="/home" 
              className={`menu-item ${activeMenu === '/home' ? 'active' : ''}`} 
              onClick={() => handleMenuClick('/home', menuItems.home)}
            >
              <FaHome size={25} /> {menuItems.home}
            </Link>
            <Link 
              to="/Categories" 
              className={`menu-item ${activeMenu === '/Categories' ? 'active' : ''}`} 
              onClick={() => handleMenuClick('/Categories', menuItems.articles)}
            >
              <FaBars size={25} /> {menuItems.articles}
            </Link>
            <Link 
              to="/bible" 
              className={`menu-item ${activeMenu === '/bible' ? 'active' : ''}`} 
              onClick={() => handleMenuClick('/bible', menuItems.bible)}
            >
              <FaBible size={20} /> {menuItems.bible}
            </Link>
            <Link 
              to="/bible-verse-find" 
              className={`menu-item ${activeMenu === '/bible-verse-find' ? 'active' : ''}`} 
              onClick={() => handleMenuClick('/bible-verse-find', menuItems.verses)}
            >
              <FaSearch size={20} /> {menuItems.verses}
            </Link>
            <Link 
              to="/search-verse-by-word" 
              className={`menu-item ${activeMenu === '/search-verse-by-word' ? 'active' : ''}`} 
              onClick={() => handleMenuClick('/search-verse-by-word', menuItems.words)}
            >
              <FaSearch size={20} /> {menuItems.words}
            </Link>
            <Link 
              to="/singers" 
              className={`menu-item ${activeMenu === '/singers' ? 'active' : ''}`} 
              onClick={() => handleMenuClick('/singers', menuItems.songs)}
            >
              {/* <FaMusic size={20} /> {menuItems.songs} */}
            </Link>
          </div>
        </div>
      </div>

      <div className="contact-info">
        <div className="social-icons">
        <Link to="/privacypolicy" className="privacy-policy-link">Privacy Policy</Link>
        <Link to="/support" className="support-link">Support</Link>
          {/* <a href="#facebook" className="social-icon" aria-label="Facebook"><FaFacebookF /></a>
          <a href="#twitter" className="social-icon" aria-label="Twitter"><FaTwitter /></a>
          <a href="#instagram" className="social-icon" aria-label="Instagram"><FaInstagram /></a> 
          <a href="#whatsapp" className="social-icon" aria-label="WhatsApp"><FaWhatsapp /></a>
          <a href="#telegram" className="social-icon" aria-label="Telegram"><FaTelegram /></a> */}
        </div>
        
        
        
        <div className="language-selector">
          <label htmlFor="language-select">{selectedLanguageText}: </label>
          <select id="language-select" value={language} onChange={handleLanguageChange}>
            <option value="English">English</option>
            <option value="Deutsch">Deutsch</option>
            <option value="தமிழ்">தமிழ்</option>
          </select>
        </div>
      </div> 
    </div>
  );
}

export default TopHeader;
