import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './SingerAndAlbumList.css';
import backendUrl from '../../BackEndURL';

function SingerAndAlbumList() {
  const [singers, setSingers] = useState([]);
  const [selectedSingerId, setSelectedSingerId] = useState(localStorage.getItem('selectedSingerId'));
  const [selectedAlbumId, setSelectedAlbumId] = useState(localStorage.getItem('selectedAlbumId'));
  const [songs, setSongs] = useState([]);
  const [allSongs, setAllSongs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [songsLoading, setSongsLoading] = useState(false);
  const [lastViewedSongId, setLastViewedSongId] = useState(localStorage.getItem('lastViewedSongId'));
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSingers = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/singers`);
        if (!response.ok) {
          throw new Error('Failed to fetch singers');
        }
        const singersData = await response.json();

        const albumsPromises = singersData.map(singer =>
          fetch(`${backendUrl}/api/singers/${singer.id}/albums`).then(res => res.json())
        );

        const albumsData = await Promise.all(albumsPromises);

        const combinedData = singersData.map((singer, index) => ({
          ...singer,
          albums: albumsData[index]
        }));

        setSingers(combinedData);

        if (selectedAlbumId) {
          fetchSongsForAlbum(selectedAlbumId);
        } else if (selectedSingerId) {
          fetchAllSongsForSinger(selectedSingerId);
        } else {
          fetchAllSongs();
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSingers();
  }, [selectedAlbumId, selectedSingerId]);

  useEffect(() => {
    if (searchQuery) {
      const filteredSongs = allSongs.filter(song =>
        song.song_name.startsWith(searchQuery)
      );
      setSongs(filteredSongs);
    } else {
      setSongs(allSongs);
    }
  }, [searchQuery, allSongs]);

  const fetchSongsForAlbum = async (albumId) => {
    try {
      setSongsLoading(true);
      const response = await fetch(`${backendUrl}/api/albums/${albumId}/songs`);
      if (!response.ok) {
        throw new Error('Failed to fetch songs');
      }
      const data = await response.json();
      setSongs(data);
      setAllSongs(data);
      setSelectedAlbumId(albumId);
      localStorage.setItem('selectedAlbumId', albumId);
    } catch (error) {
      console.error('Error fetching songs:', error);
    } finally {
      setSongsLoading(false);
    }
  };

  const fetchAllSongsForSinger = async (singerId) => {
    try {
      setSongsLoading(true);
      const albumsResponse = await fetch(`${backendUrl}/api/singers/${singerId}/albums`);
      if (!albumsResponse.ok) {
        throw new Error('Failed to fetch albums');
      }
      const albums = await albumsResponse.json();
      const songsPromises = albums.map(album =>
        fetch(`${backendUrl}/api/albums/${album.id}/songs`).then(res => res.json())
      );
      const songsData = await Promise.all(songsPromises);
      const allSongsData = songsData.flat();
      setAllSongs(allSongsData);
      setSongs(allSongsData);
    } catch (error) {
      console.error('Error fetching all songs:', error);
    } finally {
      setSongsLoading(false);
    }
  };

  const fetchAllSongs = async () => {
    try {
      setSongsLoading(true);
      const response = await fetch(`${backendUrl}/api/songs`);
      if (!response.ok) {
        throw new Error('Failed to fetch songs');
      }
      const data = await response.json();
      setAllSongs(data);
      setSongs(data);
    } catch (error) {
      console.error('Error fetching songs:', error);
    } finally {
      setSongsLoading(false);
    }
  };

  const handleSingerClick = (singerId) => {
    if (selectedSingerId === singerId) {
      setSelectedSingerId(null);
      localStorage.removeItem('selectedSingerId');
      setSelectedAlbumId(null);
      localStorage.removeItem('selectedAlbumId');
      setSongs([]);
      setAllSongs([]);
    } else {
      setSelectedSingerId(singerId);
      localStorage.setItem('selectedSingerId', singerId);
      setSelectedAlbumId(null);
      localStorage.removeItem('selectedAlbumId');
      fetchAllSongsForSinger(singerId);
    }
  };

  const handleSongClick = (songId) => {
    localStorage.setItem('lastViewedSongId', songId);
    setLastViewedSongId(songId);
    navigate(`/songs/${songId}`);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="singer-and-album-list-container">
      <div className="singer-and-album-list">
        {singers.map((singer) => (
          <div key={singer.id}>
            <div
              onClick={() => handleSingerClick(singer.id)}
              className={`singer-name ${selectedSingerId == singer.id ? 'selected' : ''}`}
            >
              <span className="name-tamil">{singer.nameTamil}</span>
              <span className="name-english">{singer.nameEnglish}</span>
            </div>
          </div>
        ))}
      </div>

      <div className="song-list">
        <input
          type="text"
          placeholder="Search for a song..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="search-box"
        />
        {songsLoading ? (
          <div>Loading songs...</div>
        ) : (
          <div>
            {selectedSingerId || searchQuery ? (
              <>
                <h3>பாடல்கள் | Songs</h3>
                {songs.length > 0 ? (
                  <ul>
                    {songs.map((song) => (
                      <li
                        key={song.id}
                        onClick={() => handleSongClick(song.id)}
                        className={lastViewedSongId === song.id ? 'highlight' : ''}
                      >
                        {song.song_name}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="instruction-container">
                    <p className="instruction-tamil">பாடலாசிரியர் மற்றும் பாகத்தை தெரிவு செய்யுங்கள்</p>
                    <p className="instruction-english">Please select a singer and an album to view songs</p>
                    <span className="arrow">←</span>
                  </div>
                )}
              </>
            ) : (
              <div className="instruction-container">
                <p className="instruction-tamil">பாடலாசிரியரை தெரிவு செய்யுங்கள்</p>
                <p className="instruction-english">Please select a singer to view songs</p>
                <span className="arrow">←</span>
              </div>
            )}
          </div>
        )}
      </div> 
    </div>
  ); 
}

export default SingerAndAlbumList;
