import { useState, useEffect } from 'react';
import axios from 'axios';
import backendUrl from '../../../BackEndURL';

const baseURL = backendUrl;

function DeutschSongs() {
  const [singers, setSingers] = useState([]);
  const [albums, setAlbums] = useState([]);
  const [songs, setSongs] = useState([]);
  const [filteredAlbums, setFilteredAlbums] = useState([]);
  const [filteredSongs, setFilteredSongs] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => { 
    fetchSingers();
    fetchAlbums();
    fetchSongs();
  }, []);

  const fetchSingers = async () => {
    try {
      const response = await axios.get(`${baseURL}/singers-deutsch`);
      setSingers(response.data);
    } catch (error) {
      console.error("Error fetching Deutsch singers", error);
    }
  };

  const fetchAlbums = async () => {
    try {
      const response = await axios.get(`${baseURL}/albums-deutsch`);
      setAlbums(response.data);
    } catch (error) {
      console.error("Error fetching Deutsch albums", error);
    }
  };

  const fetchSongs = async () => {
    try {
      const response = await axios.get(`${baseURL}/songs-deutsch`);
      setSongs(response.data);
    } catch (error) {
      console.error("Error fetching Deutsch songs", error);
    }
  };

  const filterAlbumsAndSongs = (singerId) => {
    const filteredAlbums = albums.filter(album => album.singer_id === parseInt(singerId));
    const filteredSongs = songs.filter(song => song.singer_id === parseInt(singerId));
    setFilteredAlbums(filteredAlbums);
    setFilteredSongs(filteredSongs);
  };

  const filterSongs = (albumId) => {
    const filteredSongs = songs.filter(song => song.album_id === parseInt(albumId));
    setFilteredSongs(filteredSongs);
  };

  return {
    singers,
    albums,
    songs,
    filteredAlbums,
    filteredSongs,
    message,
    setMessage, 
    fetchSingers,
    fetchAlbums,
    fetchSongs,
    filterAlbumsAndSongs,
    filterSongs
  };
}

export default DeutschSongs;
