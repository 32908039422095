import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faTwitter, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "./DailyVerse.css";
import backendUrl from "../../BackEndURL";
import { AuthContext } from "../../components/Authentication/AuthContext";
import { getEnglishBookName, getDeutschBookName } from "./BookNames"; 

function DailyVerse() {
  const { language } = useContext(AuthContext); 
  const [dailyVerse, setDailyVerse] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState("");
  const [showShareOptions, setShowShareOptions] = useState(false);

  useEffect(() => {
    const fetchDailyVerse = async () => {
      const storedVerse = JSON.parse(localStorage.getItem("dailyVerse"));
      const storedTimestamp = localStorage.getItem("verseTimestamp");

      // Check if 24 hours have passed since the last fetch
      if (storedVerse && storedTimestamp && Date.now() - storedTimestamp < 24 * 60 * 60 * 1000) {
        setDailyVerse(storedVerse);
        setBackgroundImage(`url(${chooseBackgroundImage()})`);
      } else {
        try {
          const response = await fetch(`${backendUrl}/api/dailyverses`);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          const randomIndex = Math.floor(Math.random() * data.length);
          const selectedVerse = data[randomIndex];
          setDailyVerse(selectedVerse); 
          setBackgroundImage(`url(${chooseBackgroundImage()})`);

          // Store selected verse and timestamp in localStorage
          localStorage.setItem("dailyVerse", JSON.stringify(selectedVerse));
          localStorage.setItem("verseTimestamp", Date.now().toString());
        } catch (error) {
          console.error("Error fetching daily verse:", error);
        }
      }
    };

    fetchDailyVerse();
  }, [language]); 

  const chooseBackgroundImage = () => {
    return "path-to-image";
  };

  const getBookName = (tamilBookName) => {
    if (language === "English") {
      return getEnglishBookName(tamilBookName);
    } else if (language === "Deutsch") {
      return getDeutschBookName(tamilBookName);
    } else {
      return tamilBookName; 
    }
  };

  const toggleShareOptions = () => {
    setShowShareOptions(!showShareOptions);
  };

  const shareOnSocialMedia = async (platform) => {
    let url = "";
    const text = `${dailyVerse.book} ${dailyVerse.chapter}:${dailyVerse.verse} - ${
      language === "English"
        ? dailyVerse.english
        : language === "Deutsch"
        ? dailyVerse.deutsch
        : dailyVerse.tamil
    }`;

    switch (platform) {
      case "facebook":
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          window.location.href
        )}`;
        break;
      case "twitter":
        url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`;
        break;
      case "whatsapp":
        url = `https://api.whatsapp.com/send?text=${encodeURIComponent(text)}`;
        break;
      default:
        break;
    }

    const shareWindow = window.open(url);
    if (shareWindow) {
      shareWindow.focus();
    }
  };

  return (
    <div className="daily-verse-container" style={{ backgroundImage }}>
      {dailyVerse ? (
        <div className="daily-verse">
          <h3 className="custom-heading">
            {language === "English"
              ? "🙏 Daily Verse"
              : language === "Deutsch"
              ? "🙏 Täglicher Vers"
              : "🙏 இன்றைய வேத வசனம்"}
          </h3>
          <div className="verse-text-container">
            <h3 className="book">
              <span className="verse-info">
                <span className="book-name">{getBookName(dailyVerse.book)} </span>
                <span>{dailyVerse.chapter}:</span>
                <span>{dailyVerse.verse}</span>
              </span>
            </h3>
            <p className="verse-text">
              {language === "English"
                ? dailyVerse.english
                : language === "Deutsch"
                ? dailyVerse.deutsch
                : dailyVerse.tamil}{" "}
              {/* Display verse text according to the language */}
            </p>
          </div>
          <div className="share-icons">
            <button onClick={toggleShareOptions}>
              <FontAwesomeIcon icon={faShare} />
            </button>
          </div>
          {showShareOptions && (
            <div className="share-options">
              <button onClick={() => shareOnSocialMedia("facebook")}>
                <FontAwesomeIcon icon={faFacebook} />
              </button>
              <button onClick={() => shareOnSocialMedia("twitter")}>
                <FontAwesomeIcon icon={faTwitter} />
              </button>
              <button onClick={() => shareOnSocialMedia("whatsapp")}>
                <FontAwesomeIcon icon={faWhatsapp} />
              </button>
            </div>
          )}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default DailyVerse;
