import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './NewsContent.css'; 
import backendUrl from '../../BackEndURL';

function NewsContent() {
  const [news, setNews] = useState(null);
  const { id } = useParams();
 
  useEffect(() => {
    fetchNews();
  }, [id]);

  const fetchNews = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/news/${id}`);
      setNews(response.data);
    } catch (error) {
      console.error('Error fetching news:', error);
    }
  };
 
  const handleLike = async () => {
    try {
      await axios.post(`${backendUrl}/api/news/${id}/like`);
      fetchNews(); 
    } catch (error) {
      console.error('Error liking news:', error);
    }
  };

  if (!news) {
    return <div>News not found</div>;
  }

  return (
    <div className="news-content-container">
      <h2 className="news-title">{news.title}</h2>
      <h3 className="news-subtitle">{news.subtitle}</h3>
      {news.image_url && (
        <img
          src={news.image_url}
          alt={news.title}
          className="news-image"
        />
      )}
      <p className="news-text">{news.content}</p>
      <div className="like-comment-share-container">
        <div>
          <button onClick={handleLike}>Like</button>
          <span className="like-count">{news.likes}</span>
        </div>
      </div>
    </div>
  );
}

export default NewsContent;