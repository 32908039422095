import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../Authentication/AuthContext';
import { EnglishToTamilWords, EnglishToCorrectEnglish, EnglishToDeutsch } from '../Articles/EnglishToTamilWords';
import { getEnglishBookName, getTamilBookName, getDeutschBookName } from '../Bible/OldTestament/BookNames';
import profilePic from '../../assets/thaj.png';
import backendUrl from '../../BackEndURL';
import './Footer.css';

function Footer() {
  const [subcategories, setSubcategories] = useState([]);
  const [oldTestamentBooks, setOldTestamentBooks] = useState([]);
  const [newTestamentBooks, setNewTestamentBooks] = useState([]);
  const [loadingTopics, setLoadingTopics] = useState(true);
  const [loadingBooks, setLoadingBooks] = useState(true);
  const [error, setError] = useState(null);
  const { language } = useContext(AuthContext);
  const navigate = useNavigate();

  const fetchSubcategories = useCallback(async () => {
    setLoadingTopics(true);
    try {
      let endpoint =
        language === 'Tamil'
          ? '/api/categories'
          : language === 'Deutsch'
          ? '/api/categories-deutsch'
          : '/api/categories-english';
      const response = await axios.get(`${backendUrl}${endpoint}`);
      const categories = response.data;
      const category = categories.find((cat) => cat.id === 2);
      const subcategoriesData = category?.subcategories || [];

      const translatedSubcategories = subcategoriesData.map((subcategory) => ({
        ...subcategory,
        subcategory_name: getSubcategoryName(subcategory.subcategory_name, language),
      }));

      setSubcategories(translatedSubcategories);
    } catch (error) {
      console.error('Error fetching subcategories:', error);
      setError(error.message);
    } finally {
      setLoadingTopics(false);
    }
  }, [language]);

  const fetchBooks = useCallback(async () => {
    setLoadingBooks(true);
    try {
      const oldResponse = await axios.get(`${backendUrl}/api/sections/1`);
      const newResponse = await axios.get(`${backendUrl}/api/sections/2`);
      setOldTestamentBooks(oldResponse.data.books || []);
      setNewTestamentBooks(newResponse.data.books || []);
    } catch (error) {
      console.error('Error fetching books:', error);
      setError(error.message);
    } finally {
      setLoadingBooks(false);
    }
  }, []);

  useEffect(() => {
    fetchSubcategories();
    fetchBooks();
  }, [fetchSubcategories, fetchBooks]);

  const getSubcategoryName = (name, language) => {
    if (language === 'Tamil' || language === 'தமிழ்') {
      return EnglishToTamilWords[name] || name;
    } else if (language === 'Deutsch') {
      return EnglishToDeutsch[name] || name;
    }
    return EnglishToCorrectEnglish[name] || name;
  };

  const getBookName = (bookName) => {
    switch (language) {
      case 'English':
        return getEnglishBookName(bookName);
      case 'Tamil':
      case 'தமிழ்':
        return getTamilBookName(bookName);
      case 'Deutsch':
        return getDeutschBookName(bookName);
      default:
        return bookName;
    }
  };

  const handleSubcategoryClick = (subcategoryId, subcategoryName) => {
    navigate(`/articles-list/${subcategoryId}`, { state: { subcategoryName } });
  };

  const handleBookClick = (bookName, testament) => {
    const testamentPath = testament === 'old' ? 'old-testament' : 'new-testament';
    const englishBookName = getEnglishBookName(bookName).replace(/\s+/g, ''); // Ensure correct English format for URL
    navigate(`/${testamentPath}/chapters/${englishBookName}`);
  };

  const getAboutMeTitle = () => {
    switch (language) {
      case 'Tamil':
      case 'தமிழ்':
        return 'என்னைப் பற்றி';
      case 'Deutsch':
        return 'Über mich';
      default:
        return 'About Me';
    }
  };

  const getTopicsTitle = () => {
    switch (language) {
      case 'Tamil':
      case 'தமிழ்':
        return 'தலைப்புக்கள்';
      case 'Deutsch':
        return 'Themen';
      default:
        return 'Topics';
    }
  };

  const getOldTestamentTitle = () => {
    switch (language) {
      case 'Tamil':
      case 'தமிழ்':
        return 'பழைய ஏற்பாடு';
      case 'Deutsch':
        return 'Altes Testament';
      default:
        return 'Old Testament';
    }
  };

  const getNewTestamentTitle = () => {
    switch (language) {
      case 'Tamil':
      case 'தமிழ்':
        return 'புதிய ஏற்பாடு';
      case 'Deutsch':
        return 'Neues Testament';
      default:
        return 'New Testament';
    }
  };

  // Placeholder content based on language
  const placeholderTopics =
    language === 'Tamil' || language === 'தமிழ்'
      ? ['பிதா', 'இயேசுகிறிஸ்து', 'இயேசுகிறிஸ்து : கேள்வி - பதில்', 'பரிசுத்த ஆவியானவர்', 'திரித்துவம்', 'தீர்க்கதரிசனம்', 'அறிவியல்', 'சரித்திரம்']
      : language === 'Deutsch'
      ? ['Gott', 'Jesus Christus', 'Jesus : Frage - Antwort', 'Heiliger Geist', 'Trinität', 'Prophezeiung', 'Wissenschaft', 'Geschichte']
      : ['God', 'Jesus Christ', 'Jesus : Question - Answer', 'Holy Spirit', 'Trinity', 'Prophecy', 'Science', 'History'];

  const placeholderOldTestament =
    language === 'Tamil' || language === 'தமிழ்'
      ? ['ஆதியாகமம்', 'யாத்திராகமம்', 'லேவியராகமம்', 'எண்ணாகமம்', 'உபாகமம்', 'யோசுவா', 'நியாயாதிபதிகள்']
      : language === 'Deutsch'
      ? ['1. Mose', '2. Mose', '3. Mose', '4. Mose', '5. Mose', 'Josua', 'Richter']
      : ['Genesis', 'Exodus', 'Leviticus', 'Numbers', 'Deuteronomy', 'Joshua', 'Judges'];

  const placeholderNewTestament =
    language === 'Tamil' || language === 'தமிழ்'
      ? ['மத்தேயு', 'மாற்கு', 'லூக்கா', 'யோவான்', 'அப்போஸ்தலருடைய நடபடிகள்', 'ரோமர்', '1 குரிந்தியர்', '2 குரிந்தியர்']
      : language === 'Deutsch'
      ? ['Matthäus', 'Markus', 'Lukas', 'Johannes', 'Apostelgeschichte', 'Römer', '1.Korinther', '2.Korinther']
      : ['Matthew', 'Mark', 'Luke', 'John', 'Acts', 'Romans', '1 Corinthians', '2 Corinthians'];

  const getProfileDescription = () => {
    switch (language) {
      case 'Tamil':
      case 'தமிழ்':
        return 'என் பெயர் க.குகாதாஜ், கர்த்தராகிய இயேசுகிறிஸ்துவின் சேவகர் மற்றும் பைபிள் மினிஸ்ட்ரீஸ் நிறுவனத்தின் நிறுவனர். தமிழ், ஆங்கிலம், ஜெர்மன் உள்ளிட்ட பல மொழிகளில் வேதாகமத்தை வழங்குகிறேன், மேலும் இன்னும் பல மொழிகள் விரைவில் சேர்க்கப்படும். தேவனுக்கே மகிமை. ஆமென்!';
      case 'Deutsch':
        return 'Mein Name ist K.Kugathaj, Diener des Herrn Jesus Christus und Gründer der Bible Ministries. Ich biete die Bibel in mehreren Sprachen an, darunter Tamil, Englisch und Deutsch, und plane, das Angebot zu erweitern. Alle Ehre sei Gott allein. Amen!';
      default:
        return 'My name is K. Kugathaj, servant of the Lord Jesus Christ and founder of Bible Ministries. I offer the Bible in multiple languages, including Tamil, English, and Deutsch, with plans to expand. All praise to God alone. Amen!';
    }
  };
 
  return (
    <>
      <footer className="footer">
        

        <div className="footer-section">
          <h4>{getTopicsTitle()}</h4>
          <ul>
            {loadingTopics ? (
              placeholderTopics.map((topic, index) => (
                <li key={index} className="footer-subcategory-link">
                  {topic}
                </li>
              ))
            ) : error ? (
              <p>{error}</p>
            ) : (
              subcategories.map((subcategory) => (
                <li key={subcategory.id}>
                  <span
                    onClick={() => handleSubcategoryClick(subcategory.id, subcategory.subcategory_name)}
                    className="footer-subcategory-link"
                  >
                    {subcategory.subcategory_name}
                  </span>
                </li>
              ))
            )}
          </ul>
        </div>

        <div className="footer-section">
          <h4>{getOldTestamentTitle()}</h4>
          <ul>
            {loadingBooks ? (
              placeholderOldTestament.map((book, index) => (
                <li key={index} className="footer-book-link">
                  {book}
                </li>
              ))
            ) : error ? (
              <p>{error}</p>
            ) : (
              oldTestamentBooks.map((book, index) => (
                <li key={index} onClick={() => handleBookClick(book.book_name, 'old')} className="footer-book-link">
                  {getBookName(book.book_name)}
                </li>
              ))
            )}
          </ul>
        </div>

        <div className="footer-section">
          <h4>{getNewTestamentTitle()}</h4>
          <ul>
            {loadingBooks ? (
              placeholderNewTestament.map((book, index) => (
                <li key={index} className="footer-book-link">
                  {book}
                </li>
              ))
            ) : error ? (
              <p>{error}</p>
            ) : (
              newTestamentBooks.map((book, index) => (
                <li key={index} onClick={() => handleBookClick(book.book_name, 'new')} className="footer-book-link">
                  {getBookName(book.book_name)}
                </li>
              ))
            )}
          </ul>
        </div>
        <div className="footer-section">
          <h4>{getAboutMeTitle()}</h4>
          <img src={profilePic} alt="K.Kugathaj" className="profile-pic" />
          <p>{getProfileDescription()}</p>
        </div>
      </footer>

      <div className="footer-bar">
        <p>Copyright © Tamil Holy Bible 2024. All rights reserved.</p>
      </div>
    </>
  );
}

export default Footer;
