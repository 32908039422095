import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => localStorage.getItem('language') || 'English');

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      setLanguage(storedLanguage); 
    }
  }, []);

  return (
    <AuthContext.Provider value={{ language, setLanguage: changeLanguage }}>
      {children}
    </AuthContext.Provider>
  );
};
