export const getEnglishBookName = (tamilBookName) => {
  const bookNameMap = {
    'Old Testament': 'Old Testament',
    'New Testament': 'New Testament',
    'Genesis': 'Genesis',
    'Exodus': 'Exodus',
    'Leviticus': 'Leviticus',
    'Numbers': 'Numbers',
    'Deuteronomy': 'Deuteronomy',
    'Joshua': 'Joshua',
    'Judges': 'Judges',
    'Ruth': 'Ruth',
    '1Samuel': '1 Samuel',
    '2Samuel': '2 Samuel',
    '1Kings': '1 Kings',
    '2Kings': '2 Kings',
    '1Chronicles': '1 Chronicles',
    '2Chronicles': '2 Chronicles',
    'Ezra': 'Ezra',
    'Nehemiah': 'Nehemiah',
    'Esther': 'Esther',
    'Job': 'Job',
    'Psalms': 'Psalms',
    'Proverbs': 'Proverbs',
    'Ecclesiastes': 'Ecclesiastes',
    'SongofSolomon': 'Song of Solomon',
    'Isaiah': 'Isaiah',
    'Jeremiah': 'Jeremiah',
    'Lamentations': 'Lamentations',
    'Ezekiel': 'Ezekiel',
    'Daniel': 'Daniel',
    'Hosea': 'Hosea',
    'Joel': 'Joel',
    'Amos': 'Amos',
    'Obadiah': 'Obadiah',
    'Jonah': 'Jonah',
    'Micah': 'Micah',
    'Nahum': 'Nahum',
    'Habakkuk': 'Habakkuk',
    'Zephaniah': 'Zephaniah',
    'Haggai': 'Haggai',
    'Zechariah': 'Zechariah',
    'Malachi': 'Malachi',
    'Matthew': 'Matthew',
    'Mark': 'Mark',
    'Luke': 'Luke',
    'John': 'John',
    'Acts': 'Acts',
    'Romans': 'Romans',
    '1Corinthians': '1 Corinthians',
    '2Corinthians': '2 Corinthians',
    'Galatians': 'Galatians',
    'Ephesians': 'Ephesians',
    'Philippians': 'Philippians',
    'Colossians': 'Colossians',
    '1Thessalonians': '1 Thessalonians',
    '2Thessalonians': '2 Thessalonians',
    '1Timothy': '1 Timothy',
    '2Timothy': '2 Timothy',
    'Titus': 'Titus',
    'Philemon': 'Philemon',
    'Hebrews': 'Hebrews',
    'James': 'James',
    '1Peter': '1 Peter',
    '2Peter': '2 Peter',
    '1John': '1 John',
    '2John': '2 John',
    '3John': '3 John',
    'Jude': 'Jude',
    'Revelation': 'Revelation'
  };
  return bookNameMap[tamilBookName] || tamilBookName;
};

export const getTamilBookName = (englishBookName) => {
  const bookNameMap = {
    'Old Testament': 'பழைய ஏற்பாடு',
    'New Testament': 'புதிய ஏற்பாடு',
    'Genesis': 'ஆதியாகமம்',
    'Exodus': 'யாத்திராகமம்',
    'Leviticus': 'லேவியராகமம்',
    'Numbers': 'எண்ணாகமம்',
    'Deuteronomy': 'உபாகமம்',
    'Joshua': 'யோசுவா',
    'Judges': 'நியாயாதிபதிகள்',
    'Ruth': 'ரூத்',
    '1Samuel': '1 சாமுவேல்',
    '2Samuel': '2 சாமுவேல்',
    '1Kings': '1 இராஜாக்கள்',
    '2Kings': '2 இராஜாக்கள்',
    '1Chronicles': '1 நாளாகமம்',
    '2Chronicles': '2 நாளாகமம்',
    'Ezra': 'எஸ்றா',
    'Nehemiah': 'நெகேமியா',
    'Esther': 'எஸ்தர்',
    'Job': 'யோபு',
    'Psalms': 'சங்கீதம்',
    'Proverbs': 'நீதிமொழிகள்',
    'Ecclesiastes': 'பிரசங்கி',
    'SongofSolomon': 'உன்னதப்பாட்டு',
    'Isaiah': 'ஏசாயா',
    'Jeremiah': 'எரேமியா',
    'Lamentations': 'புலம்பல்',
    'Ezekiel': 'எசேக்கியேல்',
    'Daniel': 'தானியேல்',
    'Hosea': 'ஓசியா',
    'Joel': 'யோவேல்',
    'Amos': 'ஆமோஸ்',
    'Obadiah': 'ஒபதியா',
    'Jonah': 'யோனா',
    'Micah': 'மீகா',
    'Nahum': 'நாகூம்',
    'Habakkuk': 'ஆபகூக்',
    'Zephaniah': 'செப்பனியா',
    'Haggai': 'ஆகாய்',
    'Zechariah': 'சகரியா',
    'Malachi': 'மல்கியா',
    'Matthew': 'மத்தேயு',
    'Mark': 'மாற்கு',
    'Luke': 'லூக்கா',
    'John': 'யோவான்',
    'Acts': 'அப்போஸ்தலருடைய நடபடிகள்',
    'Romans': 'ரோமர்',
    '1Corinthians': '1 கொரிந்தியர்',
    '2Corinthians': '2 கொரிந்தியர்',
    'Galatians': 'கலாத்தியர்',
    'Ephesians': 'எபேசியர்',
    'Philippians': 'பிலிப்பியர்',
    'Colossians': 'கொலோசெயர்',
    '1Thessalonians': '1 தெசலோனிக்கேயர்',
    '2Thessalonians': '2 தெசலோனிக்கேயர்',
    '1Timothy': '1 தீமோத்தேயு',
    '2Timothy': '2 தீமோத்தேயு',
    'Titus': 'தீத்து',
    'Philemon': 'பிலேமோன்',
    'Hebrews': 'எபிரெயர்',
    'James': 'யாக்கோபு',
    '1Peter': '1 பேதுரு',
    '2Peter': '2 பேதுரு',
    '1John': '1 யோவான்',
    '2John': '2 யோவான்',
    '3John': '3 யோவான்',
    'Jude': 'யூதா',
    'Revelation': 'வெளிப்படுத்தின விசேஷம்'
  };
  return bookNameMap[englishBookName] || englishBookName;
};

export const getDeutschBookName = (tamilBookName) => {
  const bookNameMap = {
    'Old Testament': 'Altes Testament',
    'New Testament': 'Neues Testament',
    'Genesis': '1. Mose',
    'Exodus': '2. Mose',
    'Leviticus': '3. Mose',
    'Numbers': '4. Mose',
    'Deuteronomy': '5. Mose',
    'Joshua': 'Josua',
    'Judges': 'Richter',
    'Ruth': 'Rut',
    '1Samuel': '1.Samuel',
    '2Samuel': '2.Samuel',
    '1Kings': '1.Könige',
    '2Kings': '2.Könige',
    '1Chronicles': '1.Chronik',
    '2Chronicles': '2.Chronik',
    'Ezra': 'Esra',
    'Nehemiah': 'Nehemia',
    'Esther': 'Ester',
    'Job': 'Hiob',
    'Psalms': 'Psalter',
    'Proverbs': 'Sprüche',
    'Ecclesiastes': 'Prediger',
    'SongofSolomon': 'Hohelied',
    'Isaiah': 'Jesaja',
    'Jeremiah': 'Jeremia',
    'Lamentations': 'Klagelieder',
    'Ezekiel': 'Hesekiel',
    'Daniel': 'Daniel',
    'Hosea': 'Hosea',
    'Joel': 'Joel',
    'Amos': 'Amos',
    'Obadiah': 'Obadja',
    'Jonah': 'Jona',
    'Micah': 'Micha',
    'Nahum': 'Nahum',
    'Habakkuk': 'Habakuk',
    'Zephaniah': 'Zefanja',
    'Haggai': 'Haggai',
    'Zechariah': 'Sacharja',
    'Malachi': 'Maleachi',
    'Matthew': 'Matthäus',
    'Mark': 'Markus',
    'Luke': 'Lukas',
    'John': 'Johannes',
    'Acts': 'Apostelgeschichte',
    'Romans': 'Römer',
    '1Corinthians': '1.Korinther',
    '2Corinthians': '2.Korinther',
    'Galatians': 'Galater',
    'Ephesians': 'Epheser',
    'Philippians': 'Philipper',
    'Colossians': 'Kolosser',
    '1Thessalonians': '1.Thessalonicher',
    '2Thessalonians': '2.Thessalonicher',
    '1Timothy': '1.Timotheus',
    '2Timothy': '2.Timotheus',
    'Titus': 'Titus',
    'Philemon': 'Philemon',
    'Hebrews': 'Hebräer',
    'James': 'Jakobus',
    '1Peter': '1.Petrus',
    '2Peter': '2.Petrus',
    '1John': '1.Johannes',
    '2John': '2.Johannes',
    '3John': '3.Johannes',
    'Jude': 'Judas',
    'Revelation': 'Offenbarung'
  };
  return bookNameMap[tamilBookName] || tamilBookName;
};
