import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../Authentication/AuthContext';
import { EnglishToTamilWords, EnglishToCorrectEnglish, EnglishToDeutsch } from './EnglishToTamilWords';
import serverUrl from '../../BackEndURL';
import './Articles.css';

export default function Articles() {
    const { subcategoryId } = useParams(); 
    const { state } = useLocation(); 
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { language } = useContext(AuthContext); 
    const navigate = useNavigate();

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                let endpoint;

                // Set the API endpoint based on the selected language
                if (language === 'Tamil' || language === 'தமிழ்') {
                    endpoint = `/api/subcategories/${subcategoryId}/articles`;
                } else if (language === 'Deutsch') {
                    endpoint = `/api/subcategories-deutsch/${subcategoryId}/articles`;
                } else {
                    endpoint = `/api/subcategories-english/${subcategoryId}/articles`;
                }

                console.log(`Fetching articles for subcategoryId: ${subcategoryId} and language: ${language}`);
                console.log(`Using API endpoint: ${serverUrl}${endpoint}`);

                const response = await axios.get(`${serverUrl}${endpoint}`);
                console.log('API response data:', response.data);

                if (response.data && Array.isArray(response.data.articles)) {
                    setArticles(response.data.articles);
                    console.log('Articles set in state:', response.data.articles);
                } else {
                    console.error('Unexpected API response format:', response.data);
                    setError(new Error('Unexpected API response format'));
                }
            } catch (error) {
                console.error('Error fetching articles:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchArticles(); 
    }, [subcategoryId, language]); 

    const getTranslation = (text) => {
        if (language === 'Tamil' || language === 'தமிழ்') {
            return EnglishToTamilWords[text] || text;
        } else if (language === 'Deutsch') {
            return EnglishToDeutsch[text] || text;
        }
        return EnglishToCorrectEnglish[text] || text;
    };

    const handleArticleClick = (articleId) => {
        // Navigate to the Article.js and pass the language as a state
        navigate(`/article/${articleId}`, { state: { language } });
    };

    if (loading) {
        console.log('Loading articles...');
        return (
            <div className="loading-container">
                <div className="loading-spinner"></div>
                <p>Loading...</p>
            </div>
        );
    }

    if (error) {
        console.log('Error occurred while loading articles:', error.message);
        return <p>Error loading articles: {error.message}</p>;
    }

    return (
        <div className="articles-container">
            <h2>{state?.subcategoryName || getTranslation('Articles')}</h2>
            
            {articles.length > 0 ? (
                <ul>
                    {articles.map((article) => (
                        <li key={article.id} onClick={() => handleArticleClick(article.id)}>
                            <h3>{article.header}</h3> 
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No articles found for this subcategory.</p>
            )}
        </div>
    );
}
