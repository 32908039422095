import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import backendUrl from '../../BackEndURL';
import './SongDetails.css';

function SongDetails() {
  const { id } = useParams();
  const [song, setSong] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const textLeftRef = useRef(null);
  const textRightRef = useRef(null);

  useEffect(() => {
    fetchSongDetails();
  }, [id]);

  const fetchSongDetails = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/songs/${id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch song details');
      }
      const data = await response.json();
      setSong(data);
      resetScroll();
    } catch (error) {
      console.error('Error fetching song details:', error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const resetScroll = () => {
    if (textLeftRef.current) {
      textLeftRef.current.scrollTop = 0;
    }
    if (textRightRef.current) {
      textRightRef.current.scrollTop = 0;
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error fetching song details: {error}</div>;
  }

  if (!song) {
    return <div>No song details available</div>;
  }

  const [textAboveHash, textBelowHash] = song.text.split('#').map(text => text.trim());

  return (
    <div className="song-details-container">
      <div className="song-details">
        <h2 className="highlight">{song.song_name}</h2>
        <div className="text-container">
          <div className="text-left" ref={textLeftRef}>
            <pre>{textAboveHash}</pre>
          </div>
          <div className="divider"></div>
          <div className="text-right" ref={textRightRef}>
            <pre>{textBelowHash}</pre>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SongDetails;
