import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../Authentication/AuthContext';
import { EnglishToTamilWords, EnglishToCorrectEnglish, EnglishToDeutsch } from './EnglishToTamilWords'; 
import serverUrl from '../../BackEndURL';
import './Categories.css'; 
import { useNavigate } from 'react-router-dom'; 

const Categories = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { language } = useContext(AuthContext); 
    const navigate = useNavigate(); 

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                let endpoint;
                if (language === 'Tamil' || language === 'தமிழ்') { 
                    endpoint = '/api/categories';
                } else if (language === 'Deutsch') {
                    endpoint = '/api/categories-deutsch';
                } else {
                    endpoint = '/api/categories-english'; 
                }

                console.log(`Fetching categories for language: ${language}, endpoint: ${endpoint}`);

                const response = await axios.get(`${serverUrl}${endpoint}`);
                console.log('Categories fetched:', response.data);
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        fetchCategories();
    }, [language]); 

    const getTranslation = (text) => {
        let translatedText;
        if (language === 'Tamil' || language === 'தமிழ்') {
            translatedText = EnglishToTamilWords[text] || text;
        } else if (language === 'Deutsch') {
            translatedText = EnglishToDeutsch[text] || text;
        } else {
            translatedText = EnglishToCorrectEnglish[text] || text;
        }
        console.log(`Translating: ${text} to ${translatedText} for language: ${language}`);
        return translatedText;
    };

    const handleCategoryClick = (categoryId) => {
        navigate(`/subcategories/${categoryId}`);
    };

    const renderCategory = (item) => (
        <div
            key={item.id}
            onClick={() => handleCategoryClick(item.id)} 
            className="category-container"
        >
            <h3 className="category-text">{getTranslation(item.category_name)}</h3>
            <p className="description-text">{item.text}</p>
        </div>
    );

    if (loading) {
        return (
            <div className="loading-container">
                <div className="loading-spinner"></div> {/* Spinner with styles */}
                <p className="loading-text">Loading...</p>
            </div>
        );
    }

    return (
        <div  className="container">
            {error ? (
                <p className="error-text">{error.message}</p>
            ) : (
                categories
                    .filter((category) => category.id === 2) // Filter to show only category with ID 2
                    .map((category) => renderCategory(category))
            )}
        </div>
    );
};

export default Categories;
