import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './NewTestamentVerses.css';
import { AuthContext } from '../../Authentication/AuthContext'; 
import { getEnglishBookName, getTamilBookName, getDeutschBookName } from './BookNames';
import backendUrl from '../../../BackEndURL';

function NewTestamentVerses() {
  const { book: oldBook, chapter: oldChapter } = useParams();
  const navigate = useNavigate();
  const { language } = useContext(AuthContext); // Use AuthContext for language state
  const [verses, setVerses] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentChapter, setCurrentChapter] = useState(parseInt(oldChapter));
  const [totalChapters, setTotalChapters] = useState(0);

  // Initializing selectedLanguages based on current language from AuthContext
  const [selectedLanguages, setSelectedLanguages] = useState([language.toLowerCase() === 'தமிழ்' ? 'tamil' : language.toLowerCase()]);

  // When language is changed, immediately reset the language selection
  useEffect(() => {
    setSelectedLanguages([language.toLowerCase() === 'தமிழ்' ? 'tamil' : language.toLowerCase()]); // Automatically select the new default language
    fetchVerses();
    updateLanguageInBackend(); // Update the backend when language is changed
  }, [oldBook, oldChapter, language]);

  // Fetch total chapters when the book changes
  useEffect(() => {
    fetchTotalChapters();
  }, [oldBook]);

  const fetchVerses = async () => {
    try {
      const url = `${backendUrl}/api/sections/2/${oldBook}/${oldChapter}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Failed to fetch verses');
      }
      const data = await response.json();
      setVerses(data.verses);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const fetchTotalChapters = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/sections/2/${oldBook}`);
      if (!response.ok) {
        throw new Error('Failed to fetch total chapters');
      }
      const data = await response.json();
      setTotalChapters(data.chapters.length);
    } catch (error) {
      setError(error.message);
    }
  };

  // Handle language selection toggle for multi-language display
  const handleLanguageToggle = (event) => {
    const lang = event.target.value;
    const isSelected = selectedLanguages.includes(lang);

    if (isSelected) {
      setSelectedLanguages(selectedLanguages.filter((selectedLang) => selectedLang !== lang));
    } else {
      setSelectedLanguages([...selectedLanguages, lang]);
    }
  };

  // Handle navigation to next chapter
  const handleNextChapter = () => {
    const nextChapter = currentChapter + 1;
    if (nextChapter <= totalChapters) {
      navigate(`/new-testament/chapters/${oldBook}/${nextChapter}`);
      setCurrentChapter(nextChapter);
    }
  };

  // Handle navigation to previous chapter
  const handlePreviousChapter = () => {
    if (currentChapter > 1) {
      const previousChapter = currentChapter - 1;
      navigate(`/new-testament/chapters/${oldBook}/${previousChapter}`);
      setCurrentChapter(previousChapter);
    }
  };

  // Handle navigation back to Bible books list
  const handleBibleBookClick = () => {
    navigate('/bible');
  };

  // Update the language preference in the backend
  const updateLanguageInBackend = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/user/language`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ language }),
      });
      if (!response.ok) {
        throw new Error('Failed to update language in the backend');
      }
    } catch (error) {
      console.error('Error updating language in backend:', error);
    }
  };

  // Handle loading and error states
  if (loading) {
    return (
      <div className="spinner-container">
        <div className="loading-spinner"></div>
        <p className="spinner-text">Loading...</p>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Get the book name based on the current selected language
  const getBookName = () => {
    switch (language) {
      case 'English':
        return getEnglishBookName(oldBook);
      case 'தமிழ்':
        return getTamilBookName(oldBook);
      case 'Deutsch':
        return getDeutschBookName(oldBook);
      default:
        return oldBook;
    }
  };

  // Set the order of the languages, ensuring the selected language comes first
  const orderedLanguages = ['tamil', 'english', 'deutsch'].sort((a, b) => {
    if (a === language.toLowerCase()) return -1;
    if (b === language.toLowerCase()) return 1;
    return 0;
  });

  return (
    <>
      <div className="new-testament-header">
        {/* Displaying chapter title */}
        <div className="chapter-title">
          {`${getBookName()} - ${oldChapter}`}
        </div>
  
        {/* Wrapper for language selection and navigation buttons */}
        <div className="header-second-line">
          {/* Language selection */}
          <div className="language-selection">
            {orderedLanguages.map((lang) => (
              <label key={lang} className={selectedLanguages.includes(lang) ? 'selected' : ''}>
                <input
                  type="checkbox"
                  value={lang}
                  checked={selectedLanguages.includes(lang)}
                  onChange={handleLanguageToggle}
                />
                {lang === 'tamil' ? 'தமிழ்' : lang === 'english' ? 'English' : 'Deutsch'}
              </label>
            ))}
          </div>
  
          {/* Navigation buttons */}
          <div className="navigation-buttons">
            <button onClick={handleBibleBookClick}>Books</button>
            <button onClick={handlePreviousChapter} disabled={currentChapter === 1}>
              Previous
            </button>
            <button onClick={handleNextChapter} disabled={currentChapter === totalChapters}>
              Next
            </button>
          </div>
        </div>
      </div>
  
      {/* Displaying verses */}
      <div className="new-testament-verses-scroll-container">
        {verses.map((verse, index) => (
          <div key={index} className="new-testament-verse-container">
            <div className="new-testament-verse-header">
              <p>{`${index + 1}. ${verse[selectedLanguages[0]]}`}</p>
            </div>
            {selectedLanguages.slice(1).map((language) => (
              <p key={language} className="verse-content">
                {verse[language]}
              </p>
            ))}
          </div>
        ))}
      </div>
    </>
  );
}

export default NewTestamentVerses;
