import React, { useState, useEffect } from 'react';
import TamilSongs from './TamilSongs';
import EnglishSongs from './EnglishSongs';
import DeutschSongs from './DeutschSongs'; 
import axios from 'axios';
import url from '../../../BackEndURL';
import './Songs.css';

const baseURL = `${url}/api`;

function Songs() {
  const { 
    singers: tamilSingers,
    filteredAlbums: tamilFilteredAlbums,
    filteredSongs: tamilFilteredSongs,
    message: tamilMessage,
    setMessage: setTamilMessage,
    fetchSingers: fetchTamilSingers,
    fetchAlbums: fetchTamilAlbums,
    fetchSongs: fetchTamilSongs,
    filterAlbumsAndSongs: filterTamilAlbumsAndSongs,
    filterSongs: filterTamilSongs
  } = TamilSongs();

  const {
    singers: englishSingers,
    filteredAlbums: englishFilteredAlbums,
    filteredSongs: englishFilteredSongs,
    message: englishMessage,
    setMessage: setEnglishMessage,
    fetchSingers: fetchEnglishSingers,
    fetchAlbums: fetchEnglishAlbums,
    fetchSongs: fetchEnglishSongs,
    filterAlbumsAndSongs: filterEnglishAlbumsAndSongs,
    filterSongs: filterEnglishSongs
  } = EnglishSongs();

  const {
    singers: deutschSingers,
    filteredAlbums: deutschFilteredAlbums,
    filteredSongs: deutschFilteredSongs,
    message: deutschMessage,
    setMessage: setDeutschMessage,
    fetchSingers: fetchDeutschSingers,
    fetchAlbums: fetchDeutschAlbums,
    fetchSongs: fetchDeutschSongs,
    filterAlbumsAndSongs: filterDeutschAlbumsAndSongs,
    filterSongs: filterDeutschSongs
  } = DeutschSongs();

  const [newSinger, setNewSinger] = useState({ nameTamil: '', nameEnglish: '', subtitle: '', image_url: '' });
  const [newAlbum, setNewAlbum] = useState({ singer_id: '', album_number: '', image_url: '' });
  const [newSong, setNewSong] = useState({ singer_id: '', album_id: '', song_name: '', text: '', image_url: '' });

  // State variables to manage dropdown visibility
  const [showTamilSingerForm, setShowTamilSingerForm] = useState(false);
  const [showTamilAlbumForm, setShowTamilAlbumForm] = useState(false);
  const [showTamilSongForm, setShowTamilSongForm] = useState(false);
  const [showEnglishSingerForm, setShowEnglishSingerForm] = useState(false);
  const [showEnglishAlbumForm, setShowEnglishAlbumForm] = useState(false);
  const [showEnglishSongForm, setShowEnglishSongForm] = useState(false);
  const [showDeutschSingerForm, setShowDeutschSingerForm] = useState(false);
  const [showDeutschAlbumForm, setShowDeutschAlbumForm] = useState(false);
  const [showDeutschSongForm, setShowDeutschSongForm] = useState(false);

  const [activeSection, setActiveSection] = useState(null); 

  useEffect(() => {
    fetchTamilSingers();
    fetchEnglishSingers();
    fetchDeutschSingers();
    fetchTamilAlbums();
    fetchEnglishAlbums();
    fetchDeutschAlbums();
    fetchTamilSongs();
    fetchEnglishSongs();
    fetchDeutschSongs();
  }, [
    fetchTamilSingers,
    fetchEnglishSingers,
    fetchDeutschSingers,
    fetchTamilAlbums,
    fetchEnglishAlbums,
    fetchDeutschAlbums,
    fetchTamilSongs,
    fetchEnglishSongs,
    fetchDeutschSongs
  ]);

  const handleInputChange = (e, setState) => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e, data, endpoint) => {
    e.preventDefault();
    setTamilMessage('');
    setEnglishMessage('');
    setDeutschMessage('');
    try {
      await axios.post(`${baseURL}/${endpoint}`, data);
      fetchTamilSingers();
      fetchTamilAlbums();
      fetchTamilSongs();
      fetchEnglishSingers();
      fetchEnglishAlbums();
      fetchEnglishSongs();
      fetchDeutschSingers();
      fetchDeutschAlbums();
      fetchDeutschSongs();
      setTamilMessage('Operation successful');
      setEnglishMessage('Operation successful');
      setDeutschMessage('Operation successful');
    } catch (error) {
      if (error.response && error.response.data) {
        setTamilMessage(`Error: ${error.response.data.message}`);
        setEnglishMessage(`Error: ${error.response.data.message}`);
        setDeutschMessage(`Error: ${error.response.data.message}`);
      } else {
        setTamilMessage('Operation failed');
        setEnglishMessage('Operation failed');
        setDeutschMessage('Operation failed');
      }
      console.error("There was an error processing the request!", error);
    }
  };

  return (
    <div className="admin-panel">
      <div className="left-section">
        <h2>Songs</h2>

        {/* Tamil Section */}
        <h3 onClick={() => {
          setShowTamilSingerForm(!showTamilSingerForm);
          setActiveSection('tamilSingers');
        }}>Create Singer - Tamil</h3>
        {showTamilSingerForm && (
          <form onSubmit={(e) => handleSubmit(e, newSinger, 'singers')}>
            <input
              type="text"
              name="nameTamil"
              placeholder="Name in Tamil"
              value={newSinger.nameTamil}
              onChange={(e) => handleInputChange(e, setNewSinger)}
              required
            />
            <input
              type="text"
              name="nameEnglish"
              placeholder="Name in English"
              value={newSinger.nameEnglish}
              onChange={(e) => handleInputChange(e, setNewSinger)}
              required
            />
            <input
              type="text"
              name="subtitle"
              placeholder="Subtitle"
              value={newSinger.subtitle}
              onChange={(e) => handleInputChange(e, setNewSinger)}
            />
            <input
              type="text"
              name="image_url"
              placeholder="Image URL"
              value={newSinger.image_url}
              onChange={(e) => handleInputChange(e, setNewSinger)}
            />
            <button type="submit">Add Singer</button>
          </form>
        )}

        <h3 onClick={() => {
          setShowTamilAlbumForm(!showTamilAlbumForm);
          setActiveSection('tamilAlbums');
        }}>Create Album - Tamil</h3>
        {showTamilAlbumForm && (
          <form onSubmit={(e) => handleSubmit(e, newAlbum, 'albums')}>
            <select name="singer_id" value={newAlbum.singer_id} onChange={(e) => {
              handleInputChange(e, setNewAlbum);
              filterTamilAlbumsAndSongs(e.target.value);
            }} required>
              <option value="">Select Singer</option>
              {tamilSingers.map(singer => (
                <option key={singer.id} value={singer.id}>{singer.nameEnglish}</option>
              ))}
            </select>
            <input
              type="number"
              name="album_number"
              placeholder="Album Number"
              value={newAlbum.album_number}
              onChange={(e) => handleInputChange(e, setNewAlbum)}
              required
            />
            <input
              type="text"
              name="image_url"
              placeholder="Image URL"
              value={newAlbum.image_url}
              onChange={(e) => handleInputChange(e, setNewAlbum)}
            />
            <button type="submit">Add Album</button>
          </form>
        )}

        <h3 onClick={() => {
          setShowTamilSongForm(!showTamilSongForm);
          setActiveSection('tamilSongs');
        }}>Create Song - Tamil</h3>
        {showTamilSongForm && (
          <form onSubmit={(e) => handleSubmit(e, newSong, 'songs')}>
            <select name="singer_id" value={newSong.singer_id} onChange={(e) => {
              handleInputChange(e, setNewSong);
              filterTamilAlbumsAndSongs(e.target.value);
            }} required>
              <option value="">Select Singer</option>
              {tamilSingers.map(singer => (
                <option key={singer.id} value={singer.id}>{singer.nameEnglish}</option>
              ))}
            </select>
            <select name="album_id" value={newSong.album_id} onChange={(e) => {
              handleInputChange(e, setNewSong);
              filterTamilSongs(e.target.value);
            }} required>
              <option value="">Select Album</option>
              {tamilFilteredAlbums.map(album => (
                <option key={album.id} value={album.id}>{`Album ${album.album_number}`}</option>
              ))}
            </select>
            <input
              type="text"
              name="song_name"
              placeholder="Song Name"
              value={newSong.song_name}
              onChange={(e) => handleInputChange(e, setNewSong)}
              required
            />
            <textarea
              name="text"
              placeholder="Song Lyrics"
              value={newSong.text}
              onChange={(e) => handleInputChange(e, setNewSong)}
              required
            />
            <input
              type="text"
              name="image_url"
              placeholder="Image URL"
              value={newSong.image_url}
              onChange={(e) => handleInputChange(e, setNewSong)}
            />
            <button type="submit">Add Song</button>
          </form>
        )}

        {/* English Section */}
        <h3 onClick={() => {
          setShowEnglishSingerForm(!showEnglishSingerForm);
          setActiveSection('englishSingers');
        }}>Create Singer - English</h3>
        {showEnglishSingerForm && (
          <form onSubmit={(e) => handleSubmit(e, newSinger, 'singers-english')}>
            <input
              type="text"
              name="nameTamil"
              placeholder="Name in Tamil"
              value={newSinger.nameTamil}
              onChange={(e) => handleInputChange(e, setNewSinger)}
              required
            />
            <input
              type="text"
              name="nameEnglish"
              placeholder="Name in English"
              value={newSinger.nameEnglish}
              onChange={(e) => handleInputChange(e, setNewSinger)}
            />
            <input
              type="text"
              name="subtitle"
              placeholder="Subtitle"
              value={newSinger.subtitle}
              onChange={(e) => handleInputChange(e, setNewSinger)}
            />
            <input
              type="text"
              name="image_url"
              placeholder="Image URL"
              value={newSinger.image_url}
              onChange={(e) => handleInputChange(e, setNewSinger)}
            />
            <button type="submit">Add Singer</button>
          </form>
        )}

        <h3 onClick={() => {
          setShowEnglishAlbumForm(!showEnglishAlbumForm);
          setActiveSection('englishAlbums');
        }}>Create Album - English</h3>
        {showEnglishAlbumForm && (
          <form onSubmit={(e) => handleSubmit(e, newAlbum, 'albums-english')}>
            <select name="singer_id" value={newAlbum.singer_id} onChange={(e) => {
              handleInputChange(e, setNewAlbum);
              filterEnglishAlbumsAndSongs(e.target.value);
            }} required>
              <option value="">Select Singer</option>
              {englishSingers.map(singer => (
                <option key={singer.id} value={singer.id}>{singer.nameTamil}</option>
              ))}
            </select>
            <input
              type="number"
              name="album_number"
              placeholder="Album Number"
              value={newAlbum.album_number}
              onChange={(e) => handleInputChange(e, setNewAlbum)}
              required
            />
            <input
              type="text"
              name="image_url"
              placeholder="Image URL"
              value={newAlbum.image_url}
              onChange={(e) => handleInputChange(e, setNewAlbum)}
            />
            <button type="submit">Add Album</button>
          </form>
        )}

        <h3 onClick={() => {
          setShowEnglishSongForm(!showEnglishSongForm);
          setActiveSection('englishSongs');
        }}>Create Song - English</h3>
        {showEnglishSongForm && (
          <form onSubmit={(e) => handleSubmit(e, newSong, 'songs-english')}>
            <select name="singer_id" value={newSong.singer_id} onChange={(e) => {
              handleInputChange(e, setNewSong);
              filterEnglishAlbumsAndSongs(e.target.value);
            }} required>
              <option value="">Select Singer</option>
              {englishSingers.map(singer => (
                <option key={singer.id} value={singer.id}>{singer.nameTamil}</option>
              ))}
            </select>
            <select name="album_id" value={newSong.album_id} onChange={(e) => {
              handleInputChange(e, setNewSong);
              filterEnglishSongs(e.target.value);
            }} required>
              <option value="">Select Album</option>
              {englishFilteredAlbums.map(album => (
                <option key={album.id} value={album.id}>{`Album ${album.album_number}`}</option>
              ))}
            </select>
            <input
              type="text"
              name="song_name"
              placeholder="Song Name"
              value={newSong.song_name}
              onChange={(e) => handleInputChange(e, setNewSong)}
              required
            />
            <textarea
              name="text"
              placeholder="Song Lyrics"
              value={newSong.text}
              onChange={(e) => handleInputChange(e, setNewSong)}
              required
            />
            <input
              type="text"
              name="image_url"
              placeholder="Image URL"
              value={newSong.image_url}
              onChange={(e) => handleInputChange(e, setNewSong)}
            />
            <button type="submit">Add Song</button>
          </form>
        )}

        {/* Deutsch Section */}
        <h3 onClick={() => {
          setShowDeutschSingerForm(!showDeutschSingerForm);
          setActiveSection('deutschSingers');
        }}>Create Singer - Deutsch</h3>
        {showDeutschSingerForm && (
          <form onSubmit={(e) => handleSubmit(e, newSinger, 'singers-deutsch')}>
            <input
              type="text"
              name="nameTamil"
              placeholder="Name in Tamil"
              value={newSinger.nameTamil}
              onChange={(e) => handleInputChange(e, setNewSinger)}
              required
            />
            <input
              type="text"
              name="nameEnglish"
              placeholder="Name in English"
              value={newSinger.nameEnglish}
              onChange={(e) => handleInputChange(e, setNewSinger)}
            />
            <input
              type="text"
              name="subtitle"
              placeholder="Subtitle"
              value={newSinger.subtitle}
              onChange={(e) => handleInputChange(e, setNewSinger)}
            />
            <input
              type="text"
              name="image_url"
              placeholder="Image URL"
              value={newSinger.image_url}
              onChange={(e) => handleInputChange(e, setNewSinger)}
            />
            <button type="submit">Add Singer</button>
          </form>
        )}

        <h3 onClick={() => {
          setShowDeutschAlbumForm(!showDeutschAlbumForm);
          setActiveSection('deutschAlbums');
        }}>Create Album - Deutsch</h3>
        {showDeutschAlbumForm && (
          <form onSubmit={(e) => handleSubmit(e, newAlbum, 'albums-deutsch')}>
            <select name="singer_id" value={newAlbum.singer_id} onChange={(e) => {
              handleInputChange(e, setNewAlbum);
              filterDeutschAlbumsAndSongs(e.target.value);
            }} required>
              <option value="">Select Singer</option>
              {deutschSingers.map(singer => (
                <option key={singer.id} value={singer.id}>{singer.nameTamil}</option>
              ))}
            </select>
            <input
              type="number"
              name="album_number"
              placeholder="Album Number"
              value={newAlbum.album_number}
              onChange={(e) => handleInputChange(e, setNewAlbum)}
              required
            />
            <input
              type="text"
              name="image_url"
              placeholder="Image URL"
              value={newAlbum.image_url}
              onChange={(e) => handleInputChange(e, setNewAlbum)}
            />
            <button type="submit">Add Album</button>
          </form>
        )}

        <h3 onClick={() => {
          setShowDeutschSongForm(!showDeutschSongForm);
          setActiveSection('deutschSongs');
        }}>Create Song - Deutsch</h3>
        {showDeutschSongForm && (
          <form onSubmit={(e) => handleSubmit(e, newSong, 'songs-deutsch')}>
            <select name="singer_id" value={newSong.singer_id} onChange={(e) => {
              handleInputChange(e, setNewSong);
              filterDeutschAlbumsAndSongs(e.target.value);
            }} required>
              <option value="">Select Singer</option>
              {deutschSingers.map(singer => (
                <option key={singer.id} value={singer.id}>{singer.nameTamil}</option>
              ))}
            </select>
            <select name="album_id" value={newSong.album_id} onChange={(e) => {
              handleInputChange(e, setNewSong);
              filterDeutschSongs(e.target.value);
            }} required>
              <option value="">Select Album</option>
              {deutschFilteredAlbums.map(album => (
                <option key={album.id} value={album.id}>{`Album ${album.album_number}`}</option>
              ))}
            </select>
            <input
              type="text"
              name="song_name"
              placeholder="Song Name"
              value={newSong.song_name}
              onChange={(e) => handleInputChange(e, setNewSong)}
              required
            />
            <textarea
              name="text"
              placeholder="Song Lyrics"
              value={newSong.text}
              onChange={(e) => handleInputChange(e, setNewSong)}
              required
            />
            <input
              type="text"
              name="image_url"
              placeholder="Image URL"
              value={newSong.image_url}
              onChange={(e) => handleInputChange(e, setNewSong)}
            />
            <button type="submit">Add Song</button>
          </form>
        )}
      </div>

      <div className="right-section">
        {tamilMessage && <p className="message">{tamilMessage}</p>}
        {englishMessage && <p className="message">{englishMessage}</p>}
        {deutschMessage && <p className="message">{deutschMessage}</p>}

        {/* Right section content based on activeSection */}
        {activeSection === 'tamilSingers' && (
          <>
            <h3>List of Tamil Singers</h3>
            <ul>
              {tamilSingers.map(singer => (
                <li key={singer.id}>
                  <div className="details">
                    {singer.nameEnglish} ({singer.nameTamil}) - {singer.subtitle}
                    {singer.image && <img src={singer.image} alt={singer.nameEnglish} />}
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}

        {activeSection === 'tamilAlbums' && (
          <>
            <h3>List of Tamil Albums</h3>
            <ul>
              {tamilFilteredAlbums.map(album => (
                <li key={album.id}>
                  <div className="details">
                    {`Album ${album.album_number}`} - Singer ID: {album.singer_id}
                    {album.image && <img src={album.image} alt={`Album ${album.album_number}`} />}
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}

        {activeSection === 'tamilSongs' && (
          <>
            <h3>List of Tamil Songs</h3>
            <ul>
              {tamilFilteredSongs.map(song => (
                <li key={song.id}>
                  <div className="details">
                    {song.song_name} - Singer ID: {song.singer_id}, Album ID: {song.album_id}
                    {song.image && <img src={song.image} alt={song.song_name} />}
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}

        {activeSection === 'englishSingers' && (
          <>
            <h3>List of English Singers</h3>
            <ul>
              {englishSingers.map(singer => (
                <li key={singer.id}>
                  <div className="details">
                    {singer.nameEnglish} ({singer.nameTamil}) - {singer.subtitle}
                    {singer.image && <img src={singer.image} alt={singer.nameEnglish} />}
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}

        {activeSection === 'englishAlbums' && (
          <>
            <h3>List of English Albums</h3>
            <ul>
              {englishFilteredAlbums.map(album => (
                <li key={album.id}>
                  <div className="details">
                    {`Album ${album.album_number}`} - Singer ID: {album.singer_id}
                    {album.image && <img src={album.image} alt={`Album ${album.album_number}`} />}
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}

        {activeSection === 'englishSongs' && (
          <>
            <h3>List of English Songs</h3>
            <ul>
              {englishFilteredSongs.map(song => (
                <li key={song.id}>
                  <div className="details">
                    {song.song_name} - Singer ID: {song.singer_id}, Album ID: {song.album_id}
                    {song.image && <img src={song.image} alt={song.song_name} />}
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}

        {activeSection === 'deutschSingers' && (
          <>
            <h3>List of Deutsch Singers</h3>
            <ul>
              {deutschSingers.map(singer => (
                <li key={singer.id}>
                  <div className="details">
                    {singer.nameEnglish} ({singer.nameTamil}) - {singer.subtitle}
                    {singer.image && <img src={singer.image} alt={singer.nameEnglish} />}
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}

        {activeSection === 'deutschAlbums' && (
          <>
            <h3>List of Deutsch Albums</h3>
            <ul>
              {deutschFilteredAlbums.map(album => (
                <li key={album.id}>
                  <div className="details">
                    {`Album ${album.album_number}`} - Singer ID: {album.singer_id}
                    {album.image && <img src={album.image} alt={`Album ${album.album_number}`} />}
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}

        {activeSection === 'deutschSongs' && (
          <>
            <h3>List of Deutsch Songs</h3>
            <ul>
              {deutschFilteredSongs.map(song => (
                <li key={song.id}>
                  <div className="details">
                    {song.song_name} - Singer ID: {song.singer_id}, Album ID: {song.album_id}
                    {song.image && <img src={song.image} alt={song.song_name} />}
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
}

export default Songs;
