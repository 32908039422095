const englishBookNameMap = {
    'பழைய ஏற்பாடு': 'Old Testament',
    'புதிய ஏற்பாடு': 'New Testament',
    'இன்றைய வேத வசனம்': 'Today\'s Bible Verse',
    'ஆதியாகமம்': 'Genesis',
    'யாத்திராகமம்': 'Exodus',
    'லேவியராகமம்': 'Leviticus',
    'எண்ணாகமம்': 'Numbers',
    'உபாகமம்': 'Deuteronomy',
    'யோசுவா': 'Joshua',
    'நியாயாதிபதிகள்': 'Judges',
    'ரூத்': 'Ruth',
    '1 சாமுவேல்': '1 Samuel',
    '2 சாமுவேல்': '2 Samuel',
    '1 இராஜாக்கள்': '1 Kings',
    '2 இராஜாக்கள்': '2 Kings',
    '1 நாளாகமம்': '1 Chronicles',
    '2 நாளாகமம்': '2 Chronicles',
    'எஸ்றா': 'Ezra',
    'நெகேமியா': 'Nehemiah',
    'எஸ்தர்': 'Esther',
    'யோபு': 'Job',
    'சங்கீதம்': 'Psalms',
    'நீதிமொழிகள்': 'Proverbs',
    'பிரசங்கி': 'Ecclesiastes',
    'உன்னதப்பாட்டு': 'Song of Solomon',
    'ஏசாயா': 'Isaiah',
    'எரேமியா': 'Jeremiah',
    'புலம்பல்': 'Lamentations',
    'எசேக்கியேல்': 'Ezekiel',
    'தானியேல்': 'Daniel',
    'ஓசியா': 'Hosea',
    'யோவேல்': 'Joel',
    'ஆமோஸ்': 'Amos',
    'ஒபதியா': 'Obadiah',
    'யோனா': 'Jonah',
    'மீகா': 'Micah',
    'நாகூம்': 'Nahum',
    'ஆபகூக்': 'Habakkuk',
    'செப்பனியா': 'Zephaniah',
    'ஆகாய்': 'Haggai',
    'சகரியா': 'Zechariah',
    'மல்கியா': 'Malachi',
    'மத்தேயு': 'Matthew',
    'மாற்கு': 'Mark',
    'லூக்கா': 'Luke',
    'யோவான்': 'John',
    'அப்போஸ்தலருடைய நடபடிகள்': 'Acts',
    'எபிரெயர்': 'Hebrews',
    'யாக்கோபு': 'James',
    'ரோமர்': 'Romans',
    '1 கொரிந்தியர்': '1 Corinthians',
    '2 கொரிந்தியர்': '2 Corinthians',
    'கலாத்தியர்': 'Galatians',
    'எபேசியர்': 'Ephesians',
    'பிலிப்பியர்': 'Philippians',
    'கொலோசெயர்': 'Colossians',
    '1 தெசலோனிக்கேயர்': '1 Thessalonians',
    '2 தெசலோனிக்கேயர்': '2 Thessalonians',
    '1 தீமோத்தேயு': '1 Timothy',
    '2 தீமோத்தேயு': '2 Timothy',
    'தீத்து': 'Titus',
    'பிலேமோன்': 'Philemon',
    'யூதா': 'Jude',
    'வெளிப்படுத்தின விசேஷம்': 'Revelation',
    '1 யோவான்': '1 John',
    '2 யோவான்': '2 John',
    '3 யோவான்': '3 John',
    '1 பேதுரு': '1 Peter',
    '2 பேதுரு': '2 Peter',
  };
  
  const deutschBookNameMap = {
    'பழைய ஏற்பாடு': 'Altes Testament',
    'புதிய ஏற்பாடு': 'Neues Testament',
    'இன்றைய வேத வசனம்': 'Der heutige Bibelvers',
    'ஆதியாகமம்': '1.Mose',
    'யாத்திராகமம்': '2.Mose',
    'லேவியராகமம்': '3.Mose',
    'எண்ணாகமம்': '4.Mose',
    'உபாகமம்': '5.Mose',
    'யோசுவா': 'Josua',
    'நியாயாதிபதிகள்': 'Richter',
    'ரூத்': 'Rut',
    '1 சாமுவேல்': '1.Samuel',
    '2 சாமுவேல்': '2.Samuel',
    '1 இராஜாக்கள்': '1.Könige',
    '2 இராஜாக்கள்': '2.Könige',
    '1 நாளாகமம்': '1.Chronik',
    '2 நாளாகமம்': '2.Chronik',
    'எஸ்றா': 'Esra',
    'நெகேமியா': 'Nehemia',
    'எஸ்தர்': 'Ester',
    'யோபு': 'Hiob',
    'சங்கீதம்': 'Psalter',
    'நீதிமொழிகள்': 'Sprüche',
    'பிரசங்கி': 'Prediger',
    'உன்னதப்பாட்டு': 'Hohelied',
    'ஏசாயா': 'Jesaja',
    'எரேமியா': 'Jeremia',
    'புலம்பல்': 'Klagelieder',
    'எசேக்கியேல்': 'Hesekiel',
    'தானியேல்': 'Daniel',
    'ஓசியா': 'Hosea',
    'யோவேல்': 'Joel',
    'ஆமோஸ்': 'Amos',
    'ஒபதியா': 'Obadja',
    'யோனா': 'Jona',
    'மீகா': 'Micha',
    'நாகூம்': 'Nahum',
    'ஆபகூக்': 'Habakuk',
    'செப்பனியா': 'Zefanja',
    'ஆகாய்': 'Haggai',
    'சகரியா': 'Sacharja',
    'மல்கியா': 'Maleachi',
    'மத்தேயு': 'Matthäus',
    'மாற்கு': 'Markus',
    'லூக்கா': 'Lukas',
    'யோவான்': 'Johannes',
    'அப்போஸ்தலருடைய நடபடிகள்': 'Apostelgeschichte',
    'எபிரெயர்': 'Hebräer',
    'யாக்கோபு': 'Jakobus',
    'ரோமர்': 'Römer',
    '1 கொரிந்தியர்': '1.Korinther',
    '2 கொரிந்தியர்': '2.Korinther',
    'கலாத்தியர்': 'Galater',
    'எபேசியர்': 'Epheser',
    'பிலிப்பியர்': 'Philipper',
    'கொலோசெயர்': 'Kolosser',
    '1 தெசலோனிக்கேயர்': '1.Thessalonicher',
    '2 தெசலோனிக்கேயர்': '2.Thessalonicher',
    '1 தீமோத்தேயு': '1.Timotheus',
    '2 தீமோத்தேயு': '2.Timotheus',
    'தீத்து': 'Titus',
    'பிலேமோன்': 'Philemon',
    'யூதா': 'Judas',
    'வெளிப்படுத்தின விசேஷம்': 'Offenbarung',
    '1 யோவான்': '1.Johannes',
    '2 யோவான்': '2.Johannes',
    '3 யோவான்': '3.Johannes',
    '1 பேதுரு': '1.Petrus',
    '2 பேதுரு': '2.Petrus',
  };
  
  export const getEnglishBookName = (tamilBookName) => {
    return englishBookNameMap[tamilBookName] || tamilBookName;
  };
  
  export const getDeutschBookName = (tamilBookName) => {
    return deutschBookNameMap[tamilBookName] || tamilBookName;
  };
  