import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../Authentication/AuthContext';
import { EnglishToTamilWords, EnglishToCorrectEnglish, EnglishToDeutsch } from './EnglishToTamilWords';
import serverUrl from '../../BackEndURL';
import './SubCategories.css'; 

export default function SubCategories() {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [subcategories, setSubcategories] = useState([]);

    const navigate = useNavigate();
    const { categoryId } = useParams(); 
    const { language } = useContext(AuthContext);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                let endpoint;
                if (language === 'Tamil' || language === 'தமிழ்') {
                    endpoint = '/api/categories'; 
                } else if (language === 'Deutsch') {
                    endpoint = '/api/categories-deutsch';
                } else { 
                    endpoint = '/api/categories-english';
                }

                const response = await axios.get(`${serverUrl}${endpoint}`);
                const categoriesData = response.data.map((category) => ({
                    ...category,
                    category_name: getCategoryName(category.category_name, language),
                    subcategories: category.subcategories.map((subcategory) => ({
                        ...subcategory,
                        subcategory_name: getSubcategoryName(subcategory.subcategory_name, language),
                    })),
                }));

                const selectedCategory = categoriesData.find((cat) => cat.id === Number(categoryId));
                setCategories(categoriesData);
                setSubcategories(selectedCategory ? selectedCategory.subcategories : []);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        fetchCategories();
    }, [categoryId, language]);

    const getCategoryName = (name, language) => {
        if (language === 'Tamil' || language === 'தமிழ்') {
            return EnglishToTamilWords[name] || name;
        } else if (language === 'Deutsch') {
            return EnglishToDeutsch[name] || name;
        }
        return EnglishToCorrectEnglish[name] || name;
    };

    const getSubcategoryName = (name, language) => {
        if (language === 'Tamil' || language === 'தமிழ்') {
            return EnglishToTamilWords[name] || name;
        } else if (language === 'Deutsch') {
            return EnglishToDeutsch[name] || name;
        }
        return EnglishToCorrectEnglish[name] || name;
    };

    const getTranslatedHeader = (language) => {
        if (language === 'Tamil' || language === 'தமிழ்') {
            return EnglishToTamilWords.Subcategories;
        } else if (language === 'Deutsch') {
            return EnglishToDeutsch.Subcategories; 
        }
        return EnglishToCorrectEnglish.Subcategories;
    };

    const handlePress = (subcategoryId, subcategoryName) => {
        navigate(`/articles-list/${subcategoryId}`, {
            state: { subcategoryName },
        });
    };

    if (loading) {
        return (
            <div className="loading-container">
                <div className="loading-spinner"></div>
                <p>Loading...</p>
            </div>
        );
    }

    return (
        <div className="subcategories-container">
            <h2>{getTranslatedHeader(language)}</h2> 
            {subcategories.length > 0 ? (
                <ul>
                    {subcategories.map((subcategory) => (
                        <li key={subcategory.id} onClick={() => handlePress(subcategory.id, subcategory.subcategory_name)}>
                            {subcategory.subcategory_name}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No subcategories available.</p>
            )}
            {error && <p className="error">{error.message}</p>}
        </div>
    );
}
