import React, { useState } from 'react';
import axios from 'axios';
import './NewsForm.css'; 
import backendUrl from '../../BackEndURL';

function NewsForm() {
    const [formData, setFormData] = useState({
        title: '',
        subtitle: '',
        content: '',
        image: null, 
    });

    const [submitted, setSubmitted] = useState(false); 

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: files ? files[0] : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData();
        Object.keys(formData).forEach(key => data.append(key, formData[key]));

        try {
            await axios.post(`${backendUrl}/api/news`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setSubmitted(true); 
        } catch (error) {
            console.error('Error posting news:', error);
        }
    };

    return (
        <div className="form-container">
            <div className="form-wrapper">
                <h2>News Form</h2>
                <form onSubmit={handleSubmit} className="form">
                    <input
                        type="text"
                        name="title"
                        value={formData.title}
                        onChange={handleChange} 
                        placeholder="Title"
                        className="form-input"
                    />
                    <input
                        type="text"
                        name="subtitle"
                        value={formData.subtitle}
                        onChange={handleChange}
                        placeholder="Subtitle"
                        className="form-input"
                    /> 
                    <textarea
                        name="content"
                        value={formData.content}
                        onChange={handleChange}
                        placeholder="Content"
                        className="form-textarea"
                    ></textarea>
                    <input type="file" name="image" onChange={handleChange} className="form-file" />
                    <button type="submit" className="form-button">
                        Submit
                    </button>
                </form>
                {submitted && <p className="success-message">Form submitted successfully!</p>} {/* Display message if submitted */}
            </div>
        </div>
    );
}

export default NewsForm;
