import React, { useEffect } from 'react';
import './Bible.css';
import OldTestament from './OldTestament/OldTestament'; 
import NewTestament from './NewTestament/NewTestament'; 

function Bible() {
  useEffect(() => {
    document.body.classList.add('bible-background');
    return () => {
      document.body.classList.remove('bible-background');
    };
  }, []);

  return ( 
    <div className="bible-container">
      <div className="left-content">
        <OldTestament /> 
      </div>
      <div className="separator"></div> 
      <div className="right-content">
        <NewTestament />
      </div>
    </div>
  ); 
} 

export default Bible;
