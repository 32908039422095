import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Authentication/AuthContext'; 
import { getEnglishBookName, getTamilBookName, getDeutschBookName } from './BookNames'; 
import './NewTestament.css';
import backendUrl from '../../../BackEndURL';

function NewTestament() {
  const [books, setBooks] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { language } = useContext(AuthContext); 

  useEffect(() => {
    fetchBooks();
  }, []);

  const fetchBooks = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/sections/2`);
      if (!response.data || !response.data.books) {
        throw new Error('Failed to fetch books');
      }
      setBooks(response.data.books);
    } catch (error) {
      setError(error.message);
    }
  };

  const getBookName = (bookName) => {
    switch (language) {
      case 'English':
        return getEnglishBookName(bookName);
      case 'Tamil':
      case 'தமிழ்':
        return getTamilBookName(bookName);
      case 'Deutsch':
        return getDeutschBookName(bookName);
      default:
        return bookName; 
    }
  };

  // Use backend name directly for navigation
  const handleBookClick = (bookName) => {
    navigate(`/new-testament/chapters/${bookName.replace(/\s+/g, '')}`);
  };

  const generateRows = () => {
    return books.map((book, index) => (
      <tr
        key={index}
        onClick={() => handleBookClick(book.book_name)}
        style={{ cursor: 'pointer' }}
        className="new-testament-book-row"
      >
        <td className="index-column">{index + 1}</td>
        <td className="book-name-column">{getBookName(book.book_name)}</td> 
      </tr>
    ));
  };

  const getTableHeaders = () => {
    switch (language) {
      case 'English':
        return { number: 'No.', books: 'Books' };
      case 'Deutsch':
        return { number: 'Nr.', books: 'Bücher' };
      default: 
        return { number: 'இல.', books: 'ஆகமங்கள்' };
    }
  };

  const { number, books: booksHeader } = getTableHeaders(); 

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="new-testament-container">
      <h2 className="new-testament-heading">{getBookName('New Testament')}</h2> 
      <table>
        <thead>
          <tr>
            <th className="new-testament-header-cell index-column">{number}</th> 
            <th className="new-testament-header-cell book-name-column">{booksHeader}</th> 
          </tr>
        </thead>
        <tbody>{generateRows()}</tbody>
      </table>
    </div> 
  );
}

export default NewTestament;
