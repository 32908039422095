import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './SearchWord.css';
import { getEnglishBookName, getTamilBookName, getDeutschBookName } from './BookNames';
import backendUrl from '../../../BackEndURL';
import { AuthContext } from '../../Authentication/AuthContext';

function SearchWord() {
    const { language } = useContext(AuthContext);
    const [testaments, setTestaments] = useState([]);
    const [selectedTestament, setSelectedTestament] = useState('');
    const [books, setBooks] = useState([]);
    const [selectedBook, setSelectedBook] = useState('all');
    const [chapters, setChapters] = useState([]);
    const [selectedChapter, setSelectedChapter] = useState('');
    const [searchWord, setSearchWord] = useState('');
    const [verses, setVerses] = useState([]);
    const [error, setError] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedLanguages, setSelectedLanguages] = useState([language.toLowerCase()]);
    const availableLanguages = ['tamil', 'english', 'deutsch'];

    useEffect(() => {
        const defaultLang = language.toLowerCase() === 'தமிழ்' ? 'tamil' : language.toLowerCase();
        setSelectedLanguages([defaultLang]);
        handleReset();
    }, [language]);

    useEffect(() => {
        axios.get(`${backendUrl}/api/sections`)
            .then(response => {
                setTestaments(response.data);
            })
            .catch(error => {
                console.error('Error fetching testaments:', error);
            });
    }, []);

    useEffect(() => {
        if (selectedTestament) {
            const testament = testaments.find(t => t.id === parseInt(selectedTestament));
            if (testament) {
                setBooks(testament.books);
            }
        }
    }, [selectedTestament, testaments]);

    useEffect(() => {
        if (selectedBook && selectedBook !== 'all') {
            axios.get(`${backendUrl}/api/sections/${selectedTestament}/${selectedBook}`)
                .then(response => {
                    setChapters(response.data.chapters);
                })
                .catch(error => {
                    console.error('Error fetching chapters:', error);
                    setChapters([]);
                });
        } else {
            setChapters([]);
        }
    }, [selectedBook, selectedTestament]);

    const getBookName = (book) => {
        switch (language) {
            case 'English':
                return getEnglishBookName(book.book_name);
            case 'Deutsch':
                return getDeutschBookName(book.book_name);
            case 'தமிழ்':
                return getTamilBookName(book.book_name);
            default:
                return book.book_name;
        }
    };

    const labels = {
        English: {
            testament: 'Testament',
            book: 'Book',
            chapter: 'Chapter',
            searchWord: 'Word',
            language: 'Language',
            selectTestament: 'Select the Testament',
            selectBook: 'Select the Book',
            selectChapter: 'Select the Chapter',
            searchWordPlaceholder: 'Type the Word',
        },
        Tamil: {
            testament: 'ஏற்பாடு',
            book: 'ஆகமம்',
            chapter: 'அதிகாரம்',
            searchWord: 'வார்த்தை',
            language: 'மொழி',
            selectTestament: 'ஏற்பாட்டைத் தேர்ந்தெடுக்கவும்',
            selectBook: 'ஆகமத்தைத் தேர்ந்தெடுக்கவும்',
            selectChapter: 'அதிகாரத்தைத் தேர்ந்தெடுக்கவும்',
            searchWordPlaceholder: 'சொல்லை உள்ளிடவும்',
        },
        Deutsch: {
            testament: 'Testament',
            book: 'Buch',
            chapter: 'Kapitel',
            searchWord: 'Wort',
            language: 'Sprache',
            selectTestament: 'Wähle das Testament',
            selectBook: 'Wähle das Buch',
            selectChapter: 'Wähle das Kapitel',
            searchWordPlaceholder: 'Geben Sie das Wort ein',
        }
    };

    const currentLabels = language === 'English' ? labels.English :
                          language === 'Deutsch' ? labels.Deutsch : labels.Tamil;

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!selectedBook) {
            setError('ஆகமத்தை தேர்வு செய்யுங்கள்!');
            return;
        }

        if (selectedLanguages.length === 0) {
            setError('Select at least one language');
            return;
        }

        setIsLoading(true);  

        const fetchVerses = async () => {
            let versesData = [];
            let errorOccured = false;

            if (selectedBook === 'all') {
                for (const book of books) {
                    const url = selectedChapter
                        ? `${backendUrl}/api/sections/${selectedTestament}/${book.book_name.toLowerCase()}/${selectedChapter}`
                        : `${backendUrl}/api/sections/${selectedTestament}/${book.book_name.toLowerCase()}`;
                    try {
                        const response = await axios.get(url);
                        const bookVerses = selectedChapter ? response.data.verses : response.data.chapters.reduce((combinedVerses, chapter) => combinedVerses.concat(chapter.verses), []);
                        versesData = versesData.concat(bookVerses.map(verse => ({ ...verse, book_name: book.book_name })));
                    } catch (error) {
                        console.error(`Error fetching verses for book ${book.book_name}:`, error);
                        errorOccured = true;
                    }
                }
            } else {
                const url = selectedChapter
                    ? `${backendUrl}/api/sections/${selectedTestament}/${selectedBook}/${selectedChapter}`
                    : `${backendUrl}/api/sections/${selectedTestament}/${selectedBook}`;
                try {
                    const response = await axios.get(url);
                    versesData = selectedChapter ? response.data.verses : response.data.chapters.reduce((combinedVerses, chapter) => combinedVerses.concat(chapter.verses), []);
                    versesData = versesData.map(verse => ({ ...verse, book_name: selectedBook }));
                } catch (error) {
                    console.error('Error fetching verses:', error);
                    errorOccured = true;
                }
            }

            if (errorOccured) {
                setError('Failed to fetch verses for some books');
            } else if (versesData.length > 0) {
                if (searchWord.trim()) {
                    const searchTerm = searchWord.trim().toLowerCase();
                    const filteredVerses = versesData.filter(verse => {
                        const foundInEnglish = verse.english && verse.english.toLowerCase().includes(searchTerm);
                        const foundInTamil = verse.tamil && verse.tamil.toLowerCase().includes(searchTerm);
                        const foundInDeutsch = verse.deutsch && verse.deutsch.toLowerCase().includes(searchTerm);
                        return foundInEnglish || foundInTamil || foundInDeutsch;
                    });
                    versesData = filteredVerses;
                }

                setVerses(versesData);
                setError('');
            } else {
                setVerses([]);
                setError('No verses found matching the criteria');
            }
            setIsLoading(false); 
            setIsSubmitted(true);
        };

        fetchVerses();
    };

    const handleReset = () => {
        setSelectedTestament('');
        setSelectedBook('all');
        setSelectedChapter('');
        setSearchWord('');
        setVerses([]);
        setError('');
        setIsSubmitted(false);
        const defaultLang = language === 'தமிழ்' ? 'tamil' : language.toLowerCase();
        setSelectedLanguages([defaultLang]);
    };

    const highlightWord = (text, wordToHighlight) => {
        const regex = new RegExp(`(${wordToHighlight})`, 'gi');
        return text.replace(regex, '<span class="highlight">$1</span>');
    };

    const renderVerse = (verse, index) => {
        if (selectedLanguages.length === 0) {
            return null;
        }

        const verseContent = {
            tamil: selectedLanguages.includes('tamil') ? `${verse.tamil}` : null,
            english: selectedLanguages.includes('english') ? `${verse.english}` : null,
            deutsch: selectedLanguages.includes('deutsch') ? `${verse.deutsch}` : null,
        };

        const bookName = {
            tamil: selectedLanguages.includes('tamil') ? getTamilBookName(verse.book_name) : null,
            english: selectedLanguages.includes('english') ? getEnglishBookName(verse.book_name) : null,
            deutsch: selectedLanguages.includes('deutsch') ? getDeutschBookName(verse.book_name) : null,
        };

        const orderedLanguages = [language.toLowerCase()].concat(
            selectedLanguages.filter(lang => lang !== language.toLowerCase())
        );

        return (
            <div key={index} className="verse-combined-column">
                {orderedLanguages.map((lang, idx) => (
                    verseContent[lang] && (
                        <p key={idx} className="verse-line">
                            {bookName[lang]} {verse.chapter_number} : {verse.verse_number} <span dangerouslySetInnerHTML={{ __html: highlightWord(verseContent[lang], searchWord) }}></span>
                        </p>
                    )
                ))}
            </div>
        );
    };

    const toggleLanguage = (lang) => {
        if (selectedLanguages.includes(lang)) {
            setSelectedLanguages(selectedLanguages.filter(l => l !== lang));
        } else {
            setSelectedLanguages([...selectedLanguages, lang]);
        }
    };

    const otherLanguages = availableLanguages.filter(lang => !selectedLanguages.includes(lang));

    return (
        <div className="searchword-container">
            <div className="searchword-form-container">
                <form onSubmit={handleSubmit}>
                    <div className="searchword-form-row">
                        <label htmlFor="testament">{currentLabels.testament}</label>
                        <select id="testament" value={selectedTestament} onChange={(e) => setSelectedTestament(e.target.value)}>
                            <option value="">{currentLabels.selectTestament}</option>
                            {testaments.map(testament => (
                                <option key={testament.id} value={testament.id}>
                                    {testament.testament}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="searchword-form-row">
                        <label htmlFor="book">{currentLabels.book}</label>
                        <select id="book" value={selectedBook} onChange={(e) => setSelectedBook(e.target.value)}>
                            <option value="all">{currentLabels.selectBook}</option>
                            {books.map(book => (
                                <option key={book.id} value={book.book_name.toLowerCase()}>
                                    {getBookName(book)}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="searchword-form-row">
                        <label htmlFor="chapter">{currentLabels.chapter}</label>
                        <select id="chapter" value={selectedChapter} onChange={(e) => setSelectedChapter(e.target.value)}>
                            <option value="">{currentLabels.selectChapter}</option>
                            {chapters.map(chapter => (
                                <option key={chapter.id} value={chapter.chapter_number}>{chapter.chapter_number}</option>
                            ))}
                        </select>
                    </div>

                    <div className="searchword-form-row">
                        <label htmlFor="searchWord">{currentLabels.searchWord}</label>
                        <input type="text" id="searchWord" value={searchWord} onChange={(e) => setSearchWord(e.target.value)} placeholder={currentLabels.searchWordPlaceholder} />
                    </div>

                    <div className="searchword-form-row">
                        <label>{currentLabels.language}</label>
                        <div className="searchword-language-selection">
                            {selectedLanguages.map(lang => (
                                <label key={lang}>
                                    <input type="checkbox" value={lang} checked={true} onChange={() => toggleLanguage(lang)} />
                                    {lang === 'tamil' ? 'Tamil' : lang === 'english' ? 'English' : 'Deutsch'}
                                </label>
                            ))}
                            {otherLanguages.map(lang => (
                                <label key={lang}>
                                    <input type="checkbox" value={lang} checked={false} onChange={() => toggleLanguage(lang)} />
                                    {lang === 'tamil' ? 'Tamil' : lang === 'english' ? 'English' : 'Deutsch'}
                                </label>
                            ))}
                        </div>
                    </div>

                    <div className="searchword-form-row searchword-submit-reset-buttons-column">
                        <button type="submit" className="searchword-submit-button">{isSubmitted ? 'Search Again' : 'Submit'}</button>
                        {isSubmitted && (
                            <button type="button" onClick={handleReset} className="searchword-reset-button">Reset</button>
                        )}
                    </div>
                </form>
            </div>

            <div className="searchword-result-container">
                {isLoading ? (
                    <div className="loading-indicator">Loading...</div>
                ) : verses.length > 0 ? (
                    <div>
                        {verses.map((verse, index) => (
                            <div key={index}>
                                {renderVerse(verse, index)}
                            </div>
                        ))}
                    </div>
                ) : (
                    <p style={{ color: "#000000", backgroundColor: "#E4E4E4", padding: 10, width: "80%", borderRadius: 10 }}>{`Note: ${currentLabels.selectTestament}, ${currentLabels.selectBook}, ${currentLabels.selectChapter}.`}</p>
                )}
                {error && <div><p>{error}</p></div>}
            </div>
        </div>
    );
}

export default SearchWord;
