import React, { useState } from 'react';
import './Contact.css';

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('message', message);

    fetch('https://example.com/send-email', {
      method: 'POST',
      body: formData,
    })
    .then(response => {
      if (response.ok) {
        alert('Your message has been sent successfully!');
        setName('');
        setEmail('');
        setMessage('');
      } else {
        alert('Failed to send your message. Please try again later.');
      }
    })
    .catch(error => {
      console.error('Error sending message:', error);
      alert('An error occurred while sending your message. Please try again later.');
    });
  };

  return (
    <div className="contact-container">
      <h2>Contact us</h2>
      <p>You have a question or want to tell us something? We appreciate your message.</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default Contact;
