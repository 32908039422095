import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TamilArticle from './TamilArticle';
import EnglishArticle from './EnglishArticle';
import DeutschArticle from './DeutschArticle';
import './Articles.css';
import url from '../../../BackEndURL';

const baseURL = `${url}/api`;

function Articles() {
  const {
    categories: tamilCategories,
    filteredSubcategories: tamilFilteredSubcategories,
    filteredArticles: tamilFilteredArticles,
    message: tamilMessage,
    setMessage: setTamilMessage,
    fetchCategories: fetchTamilCategories,
    fetchSubcategories: fetchTamilSubcategories,
    fetchArticles: fetchTamilArticles,
    filterSubcategoriesAndArticles: filterTamilSubcategoriesAndArticles,
    filterArticles: filterTamilArticles
  } = TamilArticle();

  const {
    categories: englishCategories,
    filteredSubcategories: englishFilteredSubcategories,
    filteredArticles: englishFilteredArticles,
    message: englishMessage,
    setMessage: setEnglishMessage,
    fetchCategories: fetchEnglishCategories,
    fetchSubcategories: fetchEnglishSubcategories,
    fetchArticles: fetchEnglishArticles,
    filterSubcategoriesAndArticles: filterEnglishSubcategoriesAndArticles,
    filterArticles: filterEnglishArticles
  } = EnglishArticle();

  const {
    categories: deutschCategories,
    filteredSubcategories: deutschFilteredSubcategories,
    filteredArticles: deutschFilteredArticles,
    message: deutschMessage,
    setMessage: setDeutschMessage,
    fetchCategories: fetchDeutschCategories,
    fetchSubcategories: fetchDeutschSubcategories,
    fetchArticles: fetchDeutschArticles,
    filterSubcategoriesAndArticles: filterDeutschSubcategoriesAndArticles,
    filterArticles: filterDeutschArticles
  } = DeutschArticle();

  const [newCategory, setNewCategory] = useState({ category_name: '', image: '', subheader: '', text: '' });
  const [newSubcategory, setNewSubcategory] = useState({ category_id: '', subcategory_name: '', image: '' });
  const [newArticle, setNewArticle] = useState({ category_id: '', subcategory_id: '', header: '', subheader: '', image: '', text: '' });
  const [activeSection, setActiveSection] = useState('');

  const handleInputChange = (e, setState) => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCategoryChange = (e, setState, filterType, filterFunction) => {
    const selectedCategoryId = e.target.value;
    handleInputChange(e, setState);
    if (selectedCategoryId) {
      filterFunction(selectedCategoryId);
      setActiveSection(filterType);
    } else {
      setActiveSection('');
    }
  };

  const handleSubmit = async (e, data, endpoint, fetchFunction, setMessage) => {
    e.preventDefault();
    setMessage('');

    // Ensure image field is set
    if (!data.image) {
      data.image = '';
    }

    try {
      const response = await axios.post(`${baseURL}/${endpoint}`, data);
      if (response.data) {
        fetchFunction();
        setMessage('Successfully submitted!');
      } else {
        console.error('Error: No response data');
        setMessage('Submission failed.');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.error("Error response:", error.response.data);
        setMessage(`Error: ${error.response.data.message}`);
      } else {
        console.error("Error:", error);
        setMessage('There was an error processing the request.');
      }
    }
  };

  useEffect(() => {
    fetchTamilCategories();
    fetchTamilSubcategories();
    fetchTamilArticles();
    fetchEnglishCategories();
    fetchEnglishSubcategories();
    fetchEnglishArticles();
    fetchDeutschCategories();
    fetchDeutschSubcategories();
    fetchDeutschArticles();
  }, []);

  return (
    <div className="admin-panel">
      <div className="left-section">
        <h2>Articles</h2>

        {/* Success or Error Message */}
        {tamilMessage && <div className="message">{tamilMessage}</div>}
        {englishMessage && <div className="message">{englishMessage}</div>}
        {deutschMessage && <div className="message">{deutschMessage}</div>}

        {/* Tamil Category Section */}
        <h3 onClick={() => setActiveSection('tamilCategories')}>Create Category - Tamil</h3>
        {activeSection === 'tamilCategories' && (
          <form onSubmit={(e) => handleSubmit(e, newCategory, 'categories', fetchTamilCategories, setTamilMessage)}>
            <input
              type="text"
              name="category_name"
              placeholder="Category Name"
              value={newCategory.category_name}
              onChange={(e) => handleInputChange(e, setNewCategory)}
              required
            />
            <input
              type="text"
              name="image"
              placeholder="Image URL"
              value={newCategory.image}
              onChange={(e) => handleInputChange(e, setNewCategory)}
            />
            <input
              type="text"
              name="subheader"
              placeholder="Subheader"
              value={newCategory.subheader}
              onChange={(e) => handleInputChange(e, setNewCategory)}
            />
            <textarea
              name="text"
              placeholder="Description"
              value={newCategory.text}
              onChange={(e) => handleInputChange(e, setNewCategory)}
            />
            <button type="submit">Add Category</button>
          </form>
        )}

        {/* English Category Section */}
        <h3 onClick={() => setActiveSection('englishCategories')}>Create Category - English</h3>
        {activeSection === 'englishCategories' && (
          <form onSubmit={(e) => handleSubmit(e, newCategory, 'categories-english', fetchEnglishCategories, setEnglishMessage)}>
            <input
              type="text"
              name="category_name"
              placeholder="Category Name"
              value={newCategory.category_name}
              onChange={(e) => handleInputChange(e, setNewCategory)}
              required
            />
            <input
              type="text"
              name="image"
              placeholder="Image URL"
              value={newCategory.image}
              onChange={(e) => handleInputChange(e, setNewCategory)}
            />
            <input
              type="text"
              name="subheader"
              placeholder="Subheader"
              value={newCategory.subheader}
              onChange={(e) => handleInputChange(e, setNewCategory)}
            />
            <textarea
              name="text"
              placeholder="Description"
              value={newCategory.text}
              onChange={(e) => handleInputChange(e, setNewCategory)}
            />
            <button type="submit">Add Category</button>
          </form>
        )}

        {/* German (Deutsch) Category Section */}
        <h3 onClick={() => setActiveSection('deutschCategories')}>Create Category - Deutsch</h3>
        {activeSection === 'deutschCategories' && (
          <form onSubmit={(e) => handleSubmit(e, newCategory, 'categories-deutsch', fetchDeutschCategories, setDeutschMessage)}>
            <input
              type="text"
              name="category_name"
              placeholder="Category Name"
              value={newCategory.category_name}
              onChange={(e) => handleInputChange(e, setNewCategory)}
              required
            />
            <input
              type="text"
              name="image"
              placeholder="Image URL"
              value={newCategory.image}
              onChange={(e) => handleInputChange(e, setNewCategory)}
            />
            <input
              type="text"
              name="subheader"
              placeholder="Subheader"
              value={newCategory.subheader}
              onChange={(e) => handleInputChange(e, setNewCategory)}
            />
            <textarea
              name="text"
              placeholder="Description"
              value={newCategory.text}
              onChange={(e) => handleInputChange(e, setNewCategory)}
            />
            <button type="submit">Add Category</button>
          </form>
        )}

        {/* Tamil Subcategory Section */}
        <h3 onClick={() => setActiveSection('tamilSubcategories')}>Create Subcategory - Tamil</h3>
        {activeSection === 'tamilSubcategories' && (
          <form onSubmit={(e) => handleSubmit(e, newSubcategory, 'subcategories', fetchTamilSubcategories, setTamilMessage)}>
            <select name="category_id" value={newSubcategory.category_id} onChange={(e) => handleCategoryChange(e, setNewSubcategory, 'tamilSubcategories', filterTamilSubcategoriesAndArticles)} required>
              <option value="">Select Category</option>
              {tamilCategories.map(category => (
                <option key={category.id} value={category.id}>{category.category_name}</option>
              ))}
            </select>
            <input
              type="text"
              name="subcategory_name"
              placeholder="Subcategory Name"
              value={newSubcategory.subcategory_name}
              onChange={(e) => handleInputChange(e, setNewSubcategory)}
              required
            />
            <input
              type="text"
              name="image"
              placeholder="Image URL"
              value={newSubcategory.image}
              onChange={(e) => handleInputChange(e, setNewSubcategory)}
            />
            <button type="submit">Add Subcategory</button>
          </form>
        )}

        {/* English Subcategory Section */}
        <h3 onClick={() => setActiveSection('englishSubcategories')}>Create Subcategory - English</h3>
        {activeSection === 'englishSubcategories' && (
          <form onSubmit={(e) => handleSubmit(e, newSubcategory, 'subcategories-english', fetchEnglishSubcategories, setEnglishMessage)}>
            <select name="category_id" value={newSubcategory.category_id} onChange={(e) => handleCategoryChange(e, setNewSubcategory, 'englishSubcategories', filterEnglishSubcategoriesAndArticles)} required>
              <option value="">Select Category</option>
              {englishCategories.map(category => (
                <option key={category.id} value={category.id}>{category.category_name}</option>
              ))}
            </select>
            <input
              type="text"
              name="subcategory_name"
              placeholder="Subcategory Name"
              value={newSubcategory.subcategory_name}
              onChange={(e) => handleInputChange(e, setNewSubcategory)}
              required
            />
            <input
              type="text"
              name="image"
              placeholder="Image URL"
              value={newSubcategory.image}
              onChange={(e) => handleInputChange(e, setNewSubcategory)}
            />
            <button type="submit">Add Subcategory</button>
          </form>
        )}

        {/* German (Deutsch) Subcategory Section */}
        <h3 onClick={() => setActiveSection('deutschSubcategories')}>Create Subcategory - Deutsch</h3>
        {activeSection === 'deutschSubcategories' && (
          <form onSubmit={(e) => handleSubmit(e, newSubcategory, 'subcategories-deutsch', fetchDeutschSubcategories, setDeutschMessage)}>
            <select name="category_id" value={newSubcategory.category_id} onChange={(e) => handleCategoryChange(e, setNewSubcategory, 'deutschSubcategories', filterDeutschSubcategoriesAndArticles)} required>
              <option value="">Select Category</option>
              {deutschCategories.map(category => (
                <option key={category.id} value={category.id}>{category.category_name}</option>
              ))}
            </select>
            <input
              type="text"
              name="subcategory_name"
              placeholder="Subcategory Name"
              value={newSubcategory.subcategory_name}
              onChange={(e) => handleInputChange(e, setNewSubcategory)}
              required
            />
            <input
              type="text"
              name="image"
              placeholder="Image URL"
              value={newSubcategory.image}
              onChange={(e) => handleInputChange(e, setNewSubcategory)}
            />
            <button type="submit">Add Subcategory</button>
          </form>
        )}

        {/* Tamil Article Section */}
        <h3 onClick={() => setActiveSection('tamilArticles')}>Create Article - Tamil</h3>
        {activeSection === 'tamilArticles' && (
          <form onSubmit={(e) => handleSubmit(e, newArticle, 'articles', fetchTamilArticles, setTamilMessage)}>
            <select name="category_id" value={newArticle.category_id} onChange={(e) => handleCategoryChange(e, setNewArticle, 'tamilArticles', filterTamilSubcategoriesAndArticles)} required>
              <option value="">Select Category</option>
              {tamilCategories.map(category => (
                <option key={category.id} value={category.id}>{category.category_name}</option>
              ))}
            </select>
            <select name="subcategory_id" value={newArticle.subcategory_id} onChange={(e) => {
              handleInputChange(e, setNewArticle);
              filterTamilArticles(e.target.value);
            }} required>
              <option value="">Select Subcategory</option>
              {tamilFilteredSubcategories.map(subcategory => (
                <option key={subcategory.id} value={subcategory.id}>{subcategory.subcategory_name}</option>
              ))}
            </select>
            <input
              type="text"
              name="header"
              placeholder="Article Header"
              value={newArticle.header}
              onChange={(e) => handleInputChange(e, setNewArticle)}
              required
            />
            <input
              type="text"
              name="subheader"
              placeholder="Subheader"
              value={newArticle.subheader}
              onChange={(e) => handleInputChange(e, setNewArticle)}
            />
            <input
              type="text"
              name="image"
              placeholder="Image URL"
              value={newArticle.image}
              onChange={(e) => handleInputChange(e, setNewArticle)}
            />
            <textarea
              name="text"
              placeholder="Article Text"
              value={newArticle.text}
              onChange={(e) => handleInputChange(e, setNewArticle)}
              required
            />
            <button type="submit">Add Article</button>
          </form>
        )}

        {/* English Article Section */}
        <h3 onClick={() => setActiveSection('englishArticles')}>Create Article - English</h3>
        {activeSection === 'englishArticles' && (
          <form onSubmit={(e) => handleSubmit(e, newArticle, 'articles-english', fetchEnglishArticles, setEnglishMessage)}>
            <select name="category_id" value={newArticle.category_id} onChange={(e) => handleCategoryChange(e, setNewArticle, 'englishArticles', filterEnglishSubcategoriesAndArticles)} required>
              <option value="">Select Category</option>
              {englishCategories.map(category => (
                <option key={category.id} value={category.id}>{category.category_name}</option>
              ))}
            </select>
            <select name="subcategory_id" value={newArticle.subcategory_id} onChange={(e) => {
              handleInputChange(e, setNewArticle);
              filterEnglishArticles(e.target.value);
            }} required>
              <option value="">Select Subcategory</option>
              {englishFilteredSubcategories.map(subcategory => (
                <option key={subcategory.id} value={subcategory.id}>{subcategory.subcategory_name}</option>
              ))}
            </select>
            <input
              type="text"
              name="header"
              placeholder="Article Header"
              value={newArticle.header}
              onChange={(e) => handleInputChange(e, setNewArticle)}
              required
            />
            <input
              type="text"
              name="subheader"
              placeholder="Subheader"
              value={newArticle.subheader}
              onChange={(e) => handleInputChange(e, setNewArticle)}
            />
            <input
              type="text"
              name="image"
              placeholder="Image URL"
              value={newArticle.image}
              onChange={(e) => handleInputChange(e, setNewArticle)}
            />
            <textarea
              name="text"
              placeholder="Article Text"
              value={newArticle.text}
              onChange={(e) => handleInputChange(e, setNewArticle)}
              required
            />
            <button type="submit">Add Article</button>
          </form>
        )}

        {/* German (Deutsch) Article Section */}
        <h3 onClick={() => setActiveSection('deutschArticles')}>Create Article - Deutsch</h3>
        {activeSection === 'deutschArticles' && (
          <form onSubmit={(e) => handleSubmit(e, newArticle, 'articles-deutsch', fetchDeutschArticles, setDeutschMessage)}>
            <select name="category_id" value={newArticle.category_id} onChange={(e) => handleCategoryChange(e, setNewArticle, 'deutschArticles', filterDeutschSubcategoriesAndArticles)} required>
              <option value="">Select Category</option>
              {deutschCategories.map(category => (
                <option key={category.id} value={category.id}>{category.category_name}</option>
              ))}
            </select>
            <select name="subcategory_id" value={newArticle.subcategory_id} onChange={(e) => {
              handleInputChange(e, setNewArticle);
              filterDeutschArticles(e.target.value);
            }} required>
              <option value="">Select Subcategory</option>
              {deutschFilteredSubcategories.map(subcategory => (
                <option key={subcategory.id} value={subcategory.id}>{subcategory.subcategory_name}</option>
              ))}
            </select>
            <input
              type="text"
              name="header"
              placeholder="Article Header"
              value={newArticle.header}
              onChange={(e) => handleInputChange(e, setNewArticle)}
              required
            />
            <input
              type="text"
              name="subheader"
              placeholder="Subheader"
              value={newArticle.subheader}
              onChange={(e) => handleInputChange(e, setNewArticle)}
            />
            <input
              type="text"
              name="image"
              placeholder="Image URL"
              value={newArticle.image}
              onChange={(e) => handleInputChange(e, setNewArticle)}
            />
            <textarea
              name="text"
              placeholder="Article Text"
              value={newArticle.text}
              onChange={(e) => handleInputChange(e, setNewArticle)}
              required
            />
            <button type="submit">Add Article</button>
          </form>
        )}
      </div>

      <div className="right-section">
        {activeSection === 'tamilCategories' && (
          <>
            <h3>List of Tamil Categories</h3>
            <ul>
              {tamilCategories.map(category => (
                <li key={category.id}>
                  <div className="details">
                    {category.category_name}
                    {category.image && <img src={category.image} alt={category.category_name} />}
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}

        {activeSection === 'englishCategories' && (
          <>
            <h3>List of English Categories</h3>
            <ul>
              {englishCategories.map(category => (
                <li key={category.id}>
                  <div className="details">
                    {category.category_name}
                    {category.image && <img src={category.image} alt={category.category_name} />}
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}

        {activeSection === 'deutschCategories' && (
          <>
            <h3>List of Deutsch Categories</h3>
            <ul>
              {deutschCategories.map(category => (
                <li key={category.id}>
                  <div className="details">
                    {category.category_name}
                    {category.image && <img src={category.image} alt={category.category_name} />}
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}

        {activeSection === 'tamilSubcategories' && (
          <>
            <h3>List of Tamil Subcategories</h3>
            <ul>
              {tamilFilteredSubcategories.map(subcategory => (
                <li key={subcategory.id}>
                  <div className="details">
                    {subcategory.subcategory_name}
                    {subcategory.image && <img src={subcategory.image} alt={subcategory.subcategory_name} />}
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}

        {activeSection === 'englishSubcategories' && (
          <>
            <h3>List of English Subcategories</h3>
            <ul>
              {englishFilteredSubcategories.map(subcategory => (
                <li key={subcategory.id}>
                  <div className="details">
                    {subcategory.subcategory_name}
                    {subcategory.image && <img src={subcategory.image} alt={subcategory.subcategory_name} />}
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}

        {activeSection === 'deutschSubcategories' && (
          <>
            <h3>List of Deutsch Subcategories</h3>
            <ul>
              {deutschFilteredSubcategories.map(subcategory => (
                <li key={subcategory.id}>
                  <div className="details">
                    {subcategory.subcategory_name}
                    {subcategory.image && <img src={subcategory.image} alt={subcategory.subcategory_name} />}
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}

        {activeSection === 'tamilArticles' && (
          <>
            <h3>List of Tamil Articles</h3>
            <ul>
              {tamilFilteredArticles.map(article => (
                <li key={article.id}>
                  <div className="details">
                    {article.header}
                    {article.image && <img src={article.image} alt={article.header} />}
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}

        {activeSection === 'englishArticles' && (
          <>
            <h3>List of English Articles</h3>
            <ul>
              {englishFilteredArticles.map(article => (
                <li key={article.id}>
                  <div className="details">
                    {article.header}
                    {article.image && <img src={article.image} alt={article.header} />}
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}

        {activeSection === 'deutschArticles' && (
          <>
            <h3>List of Deutsch Articles</h3>
            <ul>
              {deutschFilteredArticles.map(article => (
                <li key={article.id}>
                  <div className="details">
                    {article.header}
                    {article.image && <img src={article.image} alt={article.header} />}
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
}

export default Articles;
