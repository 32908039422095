import React, { useState, useEffect } from 'react';
import html2canvas from 'html2canvas';
import backendUrl from '../../BackEndURL';


function ShareTextImage() {
  const [dailyVerse, setDailyVerse] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const fetchDailyVerse = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/dailyverses`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const randomIndex = Math.floor(Math.random() * data.length);
        const selectedVerse = data[randomIndex];
        setDailyVerse(selectedVerse);
        preloadImage(selectedVerse.image);
      } catch (error) {
        console.error('Error fetching daily verse:', error);
      }
    };
    fetchDailyVerse();
  }, []);

  const preloadImage = (imageUrl) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      setImageLoaded(true);
    };
  };

  const shareToWhatsApp = () => {
    if (dailyVerse) {
      const textToShare = `${dailyVerse.book} ${dailyVerse.chapter}:${dailyVerse.verse} ${dailyVerse.text}`;
      const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(textToShare)}`;
      window.open(url);
    }
  };

  const combineImageAndText = () => {
    if (dailyVerse && imageLoaded) {
      const container = document.getElementById('combined-container');
      html2canvas(container).then(canvas => {
        const dataUrl = canvas.toDataURL();
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'combined_image.png';
        link.click();
      });
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      {dailyVerse && imageLoaded ? (
        <div>
          <div id="combined-container" style={{ position: 'relative' }}>
          <img src={dailyVerse.image} alt={`${dailyVerse.book} ${dailyVerse.chapter}:${dailyVerse.verse}`} style={{ width: '50%', display: 'block', margin: '0 auto' }} />
            <div style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              padding: '20px',
              borderRadius: '10px'
            }}>
              <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '24px', color: 'black', margin: '0' }}>
                <span style={{ fontWeight: 'bold', fontSize: '1.2em', marginRight: '5px' }}>{dailyVerse.book}</span>
                <span>{dailyVerse.chapter}:{dailyVerse.verse}</span>
              </p>
              <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '20px', color: 'black', margin: '0', marginTop: '10px' }}>
                {dailyVerse.text}
              </p>
            </div>
          </div>
          <button style={{ backgroundColor: '#25D366', border: 'none', color: 'white', padding: '15px 32px', textAlign: 'center', textDecoration: 'none', display: 'block', fontSize: '16px', marginTop: '20px', cursor: 'pointer' }} onClick={shareToWhatsApp}>Share to WhatsApp</button>
          <button style={{ backgroundColor: '#4CAF50', border: 'none', color: 'white', padding: '15px 32px', textAlign: 'center', textDecoration: 'none', display: 'block', fontSize: '16px', marginTop: '20px', cursor: 'pointer' }} onClick={combineImageAndText}>Combine</button>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default ShareTextImage;
