import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './News.css';
import backendUrl from '../../BackEndURL';

function News() {
  const [news, setNews] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/news`);
        setNews(response.data);
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    }; 

    fetchNews();
  }, []);

  return (
    <div className="news-container">
      <div className="news-header-container">
        <h1 className="news-header">Latest News</h1>
      </div>
      <div className="news-items">
        {news.map((item) => (
          <div className="news-item" key={item.id}>
            <Link to={`/news/${item.id}`} target="" rel="noopener noreferrer">
              <h2 className="news-title">{item.title}</h2>
            </Link>
            <h3 className="news-subtitle">{item.subtitle}</h3>
            {item.image_url && (
              <Link to={`/news/${item.id}`} target="" rel="noopener noreferrer">
                <img src={item.image_url} alt={item.title} className="news-image"/>
              </Link>
            )}
            {/* Content displayed without link */}
            <p className="news-content">{item.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default News;
