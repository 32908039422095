import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './ScreenSaver.css';
import { AuthContext } from '../Authentication/AuthContext';

const images = [
  'image1.jpg',
  'image2.jpg',
];

function ScreenSaver() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { language } = useContext(AuthContext);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === images.length - 1 ? 0 : prevSlide + 1));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 4000);

    return () => clearInterval(interval);
  }, [currentSlide]);

  return (
    <div className="screensaver-container">
      <div className="screensaver-slideshow" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
        {images.map((image, index) => (
          <div key={index} className="slide">
            {index === 0 && (
              <>
                <div className="centered-text">
                  {language === 'தமிழ்' 
                    ? 'வேதவாக்கியங்களெல்லாம் தேவ ஆவியினால் அருளப்பட்டிருக்கிறது.'
                    : language === 'Deutsch'
                    ? 'Denn alle Schrift, von Gott eingegeben.'
                    : 'All scripture is given by inspiration of God,'
                  }
                </div>
                <div className="bottom-text">
                  {language === 'தமிழ்' 
                    ? '2 தீமோத்தேயு - 3:16'
                    : language === 'Deutsch'
                    ? '2 Timotheus - 3:16'
                    : '2 Timothy - 3:16'
                  }
                </div>
              </>
            )}
            {index === 1 && (
              <>
                <div className="centered-text">
                  {language === 'தமிழ்' 
                    ? 'ஒருவன் கர்த்தராகிய இயேசுகிறிஸ்துவினிடத்தில் அன்புகூராமற்போனால், அவன் சபிக்கப்பட்டவனாயிருக்கக்கடவன், கர்த்தர் வருகிறார்.'
                    : language === 'Deutsch'
                    ? 'Wenn jemand den Herrn nicht lieb hat, der sei verflucht. Maranatha!'
                    : 'If any man love not the Lord Jesus Christ, let him be Anathema Maranatha.'
                  }
                </div>
                <div className="bottom-text">
                  {language === 'தமிழ்' 
                    ? '1 கொரிந்தியர் - 16:22'
                    : language === 'Deutsch'
                    ? '1 Korinther - 16:22'
                    : '1 Corinthians - 16:22'
                  }
                </div>
              </>
            )}
            <img
              src={require(`./images/${image}`)}
              alt={`Slide ${index + 1}`}
              className="slide-image"
            />
          </div> 
        ))}
      </div>
      <div className="slide-dots">
        {images.map((_, index) => (
          <div
            key={index}
            className={`dot ${index === currentSlide ? 'active' : ''}`}
            onClick={() => setCurrentSlide(index)}
          />
        ))}
      </div>
      <button className="prev-btn" onClick={() => setCurrentSlide(prev => (prev === 0 ? images.length - 1 : prev - 1))}>
        <FontAwesomeIcon icon={faChevronLeft} /> 
      </button>
      <button className="next-btn" onClick={nextSlide}>
        <FontAwesomeIcon icon={faChevronRight} /> 
      </button>
    </div>
  );
} 

export default ScreenSaver;
