import React from "react";
import "./Support.css";

const Support = () => {
  return (
    <div className="support-container">
      <h2>Support</h2>
      <p>
        Thank you for visiting our support page. If you have any inquiries, need
        assistance, or want to report an issue, feel free to reach out to us via
        email. We are always happy to help!
      </p>
      <p>
        For any inquiries, please contact us at:
        <a href="mailto:kugathaj@gmail.com" className="support-email">
          {" "}
          kugathaj.k@gmail.com
        </a>
      </p>
    </div>
  );
};

export default Support;
